const SignupToAdmin = ({ firstName, lastName, email, phone }) => {
    return {
        subject: 'New Signup for Axis M&A',
        body: `<p>Hey Axis M&amp;A, You have got a new Sign-up!</p>
            <p>Here are the details:</p>
            <p>
                First Name: ${firstName}
                <br />
                Last Name: ${lastName}
                <br />
                Email: ${email}
                <br />
                Phone: ${phone}
            </p>
            <p>
                Cheers,
                <br />
                Team Axis M&amp;A
                <br />
            </p>`,
        recipient: 'admin@axisma.com'
    };
};

const BookAppoinmentToAdmin = ({ name, email, contactNumber, enquiry }) => {
    return {
        subject: 'Book your Appointment Request',
        body: `<p>Hey Axis M&amp;A, You have got a new Appointment Request!</p>
            <p>Here is the details:</p>
            <p>
                Name: ${name}
                <br />
                Email: ${email}
                <br />
                Contact Number: ${contactNumber}
                <br />
                Inquiry for: ${enquiry}
            </p>
            <p>
                Cheers,
                <br />
                Team Axis M&amp;A
                <br />
            </p>`,
        recipient: 'admin@axisma.com'
    };
};

const BookAppoinmentToRecipient = ({ name, email }) => {
    return {
        subject: 'Appointment Request with Axis M&A',
        body: `<p>Hi ${name},</p>
            <p>Thank you for your Inquiry.</p>
            <p>We are reviewing your request, and we will contact you as soon as possible.</p>
            <p>Cheers,<br />
                Team Axis M&amp;A<br />
            </p>`,
        recipient: email
    };
};

const ContactUsToAdmin = ({ firstName, lastName, companyName, phoneNumber, emailAddress, reasonForInterest }) => {
    return {
        subject: 'Contact Us For Playbook Details',
        body: `<p>Hey Axis M&amp;A, You have got a play book Request!</p>
            <p>Here is the details:</p>
            <p>
                Name: ${firstName} ${lastName}
                <br />
                Company Name: ${companyName}
                <br />
                Email Address: ${emailAddress}
                <br />
                Phone Number: ${phoneNumber}
                <br />
                Reason For Interest : ${reasonForInterest}
            </p>
            <p>
                Cheers,
                <br />
                Team Axis M&amp;A
                <br />
            </p>`,
        recipient: 'axisma.dev@gmail.com'
    };
};

const ContactUsToRecipient = ({ firstName, emailAddress }) => {
    return {
        subject: 'Playbook Request with Axis M&A',
        body: `<p>Hi ${firstName},</p>
            <p>Thank you for your Interest.</p>
            <p>We are reviewing your request, and we will contact you as soon as possible.</p>
            <p>Cheers,<br />
                Team Axis M&amp;A<br />
            </p>`,
        recipient: emailAddress
    };
};

export { SignupToAdmin, BookAppoinmentToAdmin, BookAppoinmentToRecipient, ContactUsToRecipient, ContactUsToAdmin };

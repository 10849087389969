// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.float-left {
  font-size: 13px;
  font-weight: medium;
  color: rgb(165, 162, 162);
}

.input-group-merge {
  margin-top: 0;
}

.float-right {
  font-size: 13px;
  font-weight: medium;
}

.input-group-text {
  background-color: #fff !important;
  color: #333;
  font-weight: medium;
  font-size: 17px;
}

.search-contianer {
  z-index: 1;
}

.form-group {
  margin-top: 165px;
}

.form-control {
  width: 580px;
  height: 48px;
  height: 45px !important;
}

.top-search-content {
  background-color: black;
  width: 100%;
  height: 219px;
}
.top-search-content .input-label {
  color: white;
  font-weight: 500;
  font-size: 14px;
}
.top-search-content .input-group {
  margin-top: 100px;
  position: relative;
  box-shadow: 2.661px 10.673px 32px 0px rgba(142, 142, 142, 0.21);
}
.top-search-content .color-black {
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/css/components/search.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,mBAAA;EACA,yBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,eAAA;EACA,mBAAA;AACJ;;AAEA;EACI,iCAAA;EACA,WAAA;EACA,mBAAA;EACA,eAAA;AACJ;;AACA;EACI,UAAA;AAEJ;;AAAA;EACI,iBAAA;AAGJ;;AADA;EACI,YAAA;EACA,YAAA;EACA,uBAAA;AAIJ;;AADA;EACI,uBAAA;EACA,WAAA;EACA,aAAA;AAIJ;AAFI;EACI,YAAA;EACA,gBAAA;EACA,eAAA;AAIR;AAFI;EACI,iBAAA;EACA,kBAAA;EACA,+DAAA;AAIR;AAFI;EACI,YAAA;AAIR","sourcesContent":[".float-left {\n    font-size: 13px;\n    font-weight: medium;\n    color: rgb(165, 162, 162);\n}\n\n.input-group-merge {\n    margin-top: 0;\n}\n\n.float-right {\n    font-size: 13px;\n    font-weight: medium;\n}\n\n.input-group-text {\n    background-color: #fff !important;\n    color: #333;\n    font-weight: medium;\n    font-size: 17px;\n}\n.search-contianer {\n    z-index: 1;\n}\n.form-group {\n    margin-top: 165px;\n}\n.form-control {\n    width: 580px;\n    height: 48px;\n    height: 45px !important;\n}\n\n.top-search-content {\n    background-color: black;\n    width: 100%;\n    height: 219px;\n\n    .input-label {\n        color: white;\n        font-weight: 500;\n        font-size: 14px;\n    }\n    .input-group {\n        margin-top: 100px;\n        position: relative;\n        box-shadow: 2.661px 10.673px 32px 0px rgba(142, 142, 142, 0.21);\n    }\n    .color-black {\n        color: black;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// @flow
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILED,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,
    VERIFY_EMAIL,
    VERIFY_EMAIL_SUCCESS,
    VERIFY_EMAIL_FAILED,
    TRIGGER_OTP,
    TRIGGER_OTP_SUCCESS,
    TRIGGER_OTP_FAILED,
    THIRD_PARTY_AUTH,
    THIRD_PARTY_AUTH_SUCCESS,
    THIRD_PARTY_AUTH_FAILED
} from './constants';

export const loginUser = (email, password, role) => ({
    type: LOGIN_USER,
    payload: { email, password, role }
});

export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user
});

export const loginUserFailed = (error) => ({
    type: LOGIN_USER_FAILED,
    payload: error
});

export const registerUser = (firstName, lastName, phone, email, password, role) => ({
    type: REGISTER_USER,
    payload: { firstName, lastName, phone, email, password, role }
});

export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user
});

export const registerUserFailed = (error) => ({
    type: REGISTER_USER_FAILED,
    payload: error
});

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: { history }
});

export const forgetPassword = (username) => ({
    type: FORGET_PASSWORD,
    payload: { username }
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus
});

export const forgetPasswordFailed = (error) => ({
    type: FORGET_PASSWORD_FAILED,
    payload: error
});

export const verifyEmail = (verifyEmailPayload) => ({
    type: VERIFY_EMAIL,
    payload: verifyEmailPayload
});

export const verifyEmailSuccess = (verifyEmailStatus) => ({
    type: VERIFY_EMAIL_SUCCESS,
    payload: verifyEmailStatus
});

export const verifyEmailFailed = (error) => ({
    type: VERIFY_EMAIL_FAILED,
    payload: error
});

export const triggerOtp = (triggerOtpPayload) => ({
    type: TRIGGER_OTP,
    payload: triggerOtpPayload
});

export const triggerOtpSuccess = (triggerOtpStatus) => ({
    type: TRIGGER_OTP_SUCCESS,
    payload: triggerOtpStatus
});

export const triggerOtpFailed = (error) => ({
    type: TRIGGER_OTP_FAILED,
    payload: error
});

export const thirdPartyAuth = (thirdPartyAuthResponse, service, role) => ({
    type: THIRD_PARTY_AUTH,
    payload: { thirdPartyAuthResponse, service, role }
});

export const thirdPartyAuthSuccess = (authResponse) => ({
    type: THIRD_PARTY_AUTH_SUCCESS,
    payload: authResponse
});

export const thirdPartyAuthFailed = (error) => ({
    type: THIRD_PARTY_AUTH_FAILED,
    payload: error
});

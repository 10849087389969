import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { Component } from 'react';
import Slider from 'react-slick';
import LazyImage from '../../components/lazyImage';

import businessValuations from '../../assets/images/business-valuations.jpg';
import ourProcess from '../../assets/images/our-process-1.jpg';
import ourProcessone from '../../assets/images/our-process-1.jpg';
import exitStrategies from '../../assets/images/exit-strategies.jpg';
import businessValuationsPoster from '../../assets/images/poster/business-valuations.jpg';
import ourProcessPoster from '../../assets/images/poster/our-process.jpg';
import exitStrategiesPoster from '../../assets/images/exit-strategies.jpg';
import '../../assets/styles/css/pages/home/job.css';

const slideImage = [
    {
        image: ourProcess,
        alt: 'm and a consulting',
        imgTitle: 'm and a consulting',
        placeholderImg: 'ourProcessPoster',
        className: 'job-image job-image-height img-fluid',
        title: 'Our Process',
        contentFirst:
            'Axis M&A leverages a truly unique buying process, from representing a company seeking to expand via acquisition, to assisting a management team looking to execute a buyout. Axis M&A has proven expertise in effectively assisting clients to execute their M&A initiatives.',
        contentSecond:
            'Axis M&A provides world-class consulting for identifying acquisition targets, conducting preliminary due diligence, establishing pricing criteria, and arranging acquisition financing for buyers.',
        href: '/our-process#valuation',
        hrefFirst: '/our-process'
    },
    {
        image: exitStrategies,
        alt: 'mergers and acquisitions companies',
        imgTitle: 'mergers and acquisitions companies',
        placeholderImg: 'exitStrategiesPoster',
        className: 'job-image img-fluid',
        title: 'Exit Strategies',
        contentFirst:
            'Axis M&A is here to guide you through the development of an appropriate and profitable selling or divestment strategy. It is critical that a forward-looking strategy is developed in view of what you are trying to accomplish. Axis M&A is able to integrate the dynamics specific to your particular company and to its industry by developing a set of benchmarks and initiatives to achieve a particular future position. Executing on all action items will ensure Axis M&A is putting the company in the best light for exit.',
        hrefFirst: '/our-process#exitStrategies',
        href: '/our-process#exitStrategies'
    },
    {
        image: businessValuations,
        alt: 'merger and acquisition deals',
        imgTitle: 'merger and acquisition deals',
        placeholderImg: 'businessValuationsPoster',
        className: 'service-image TopSlide img-fluid',
        title: 'Business Valuations',
        contentFirst:
            'If you are an owner of a tech in a tech or staffing company, and you are wondering what your company is worth, please contact the team at Axis M&A for a consultation and valuation. Our in-house valuation will ensure the proper valuation is assigned to your company and the value is accurately marketed to our exclusive list of buyers and sellers',
        href: '/our-process#valuation',
        hrefFirst: '/our-process#valuation'
    }
];

const slideImageSmall = [
    {
        image: ourProcessone,
        alt: 'our-process',
        title: 'Our Process',
        placeholderImg: ourProcessPoster,
        href: '/our-process',
        className: 'job-image job-image-height-small img-fluid'
    },
    {
        image: exitStrategiesPoster,
        alt: 'exit-strategies',
        title: ' Exit Strategies',
        placeholderImg: exitStrategiesPoster,
        href: '/our-process#exitStrategies',
        className: 'job-image job-image-height-small img-fluid'
    },
    {
        image: businessValuations,
        alt: 'business-valuations',
        title: 'Business Valuations',
        placeholderImg: businessValuationsPoster,
        href: '/our-process#valuation',
        className: 'job-image job-image-height-small img-fluid'
    }
];

export default class AsNavFor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }
    next() {
        this.slider1.slickNext();
        this.slider2.slickNext();
    }
    previous() {
        this.slider1.slickPrev();
        this.slider2.slickPrev();
    }
    render() {
        return (
            <div className="container">
                <div className="card border-0 mb-0 job-header-home">
                    <div className="card-body pt-0">
                        <div className="job-text">How we do it?</div>
                    </div>
                </div>
                <div className="job-container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Slider
                                asNavFor={this.state.nav2}
                                ref={(slider) => (this.slider1 = slider)}
                            >
                                {slideImage.map((slideImage, index) => (
                                    <div>
                                        <div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <a
                                                        className="job-img-container"
                                                        href={slideImage.hrefFirst}
                                                    >
                                                        <LazyImage
                                                            className={slideImage.className}
                                                            src={slideImage.image}
                                                            placeholderImg={slideImage.placeholderImg}
                                                            alt={slideImage.alt}
                                                            title={slideImage.imgTitle}
                                                        />
                                                    </a>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="job-slide-content">
                                                        <h3>{slideImage.title}</h3>
                                                        <p>
                                                            {slideImage.contentFirst}
                                                            <br />
                                                            {slideImage.contentSecond}{' '}
                                                        </p>
                                                        <a
                                                            className="float-right"
                                                            href={slideImage.href}
                                                        >
                                                            <i className="fas fa-plus mr-1"></i>More
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                            <div className="row">
                                <div className="col-lg-6">
                                    <Slider
                                        asNavFor={this.state.nav1}
                                        ref={(slider) => (this.slider2 = slider)}
                                        slidesToShow={3}
                                        swipeToSlide={true}
                                        focusOnSelect={true}
                                    >
                                        {slideImageSmall.map((slideImageSmall, index) => (
                                            <div>
                                                <div>
                                                    <a
                                                        className="job-img-container job-flex-8"
                                                        href={slideImageSmall.href}
                                                    >
                                                        <div className="job-text-gradient">
                                                            {slideImageSmall.title}
                                                        </div>
                                                        <LazyImage
                                                            className={slideImageSmall.className}
                                                            src={slideImageSmall.image}
                                                            placeholderImg={slideImageSmall.placeholderImg}
                                                            alt={slideImageSmall.alt}
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                                <div className="col-lg-6">
                                    <div
                                        className=" d-flex justify-content-between"
                                        style={{ textAlign: 'center' }}
                                    >
                                        <button
                                            className="btn btn-light  pre-next-btn"
                                            onClick={this.previous}
                                        >
                                            <i className="fas fa-long-arrow-alt-left"></i> Pre
                                        </button>
                                        <button
                                            className="btn btn-light pre-next-btn"
                                            onClick={this.next}
                                        >
                                            Next <i className="fas fa-long-arrow-alt-right"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

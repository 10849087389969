import React from 'react';
// import { Link } from 'react-router-dom';

// import { Pagination } from '../../components/pagination';
import utils from '../../lib/utils';
// import Similar from '../company/similar';

import '../../assets/styles/css/pages/company/companyList.scss';
import { TablePagination } from '@material-ui/core';

export default class CompanyList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            companyList: [],
            currentPage: 0,
            companiesPerPage: 4
        };
    }

    handleChangePage = (event, newPage) => {
        this.setState({ currentPage: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            companiesPerPage: parseInt(event.target.value, 10),
            currentPage: 0
        });
    };

    render() {
        const { companyDataList } = this.props;
        const { currentPage, companiesPerPage } = this.state;
        const startIndex = (currentPage + 1) * companiesPerPage - companiesPerPage;
        const endIndex = startIndex + companiesPerPage;

        return (
            <div className="container">
                <ul className="list-group company-list mb-4">
                    <TablePagination
                        component="div"
                        count={companyDataList.length}
                        page={currentPage}
                        onChangePage={this.handleChangePage}
                        rowsPerPage={companiesPerPage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        rowsPerPageOptions={[4, 5, 10]}
                        className="d-flex"
                    />
                    {companyDataList
                        .slice(startIndex, endIndex)
                        .map(({ companyName, country, city, sellerID }, index) => (
                            <li className="list-group-item shadow" key={`company-list-${index}`}>
                                <div className="media">
                                    <img
                                        className="mr-3"
                                        src={utils.letterAvatar(companyName)}
                                        alt="Generic placeholder"
                                    />
                                    <div className="media-body">
                                        <button className="btn float-right  btn-pill mt-2">
                                            View
                                            {/* <Link to={`/company/${sellerID}`}> View</Link> */}
                                        </button>
                                        <h5 className="mt-2 mb-0">{companyName}</h5>
                                        <span className="text-muted d-flex">
                                            {city}, {country}{' '}
                                        </span>
                                    </div>
                                </div>
                            </li>
                        ))}
                </ul>
            </div>
        );
    }
}

import React from 'react';

import LazyImage from '../../components/lazyImage';

import exitStrategies from '../../assets/images/exit-strategies.jpg';
import exitStrategiesPoster from '../../assets/images/poster/exit-strategies.jpg';
import '../../assets/styles/css/pages/services/services.css';

const ExitStrategies = () => {
    return (
        <div className="container" id="exitStrategies">
            <div className="row no-gutter">
                <div className="col-lg-12">
                    <div className="card bg-grey">
                        <div className="card-body">
                            <h2 className="buy_side_header mb-4">Exit Strategies </h2>
                            <div className="row">
                                <div className="col-lg-6">
                                    <LazyImage
                                        className="buy_side_representation_image img-fluid"
                                        src={exitStrategies}
                                        placeholderImg={exitStrategiesPoster}
                                        alt="exit-strategies"
                                    />
                                    <p className="buy_side_representation_content mt-4"></p>
                                </div>
                                <div className="col-lg-6">
                                    <p className="buy_side_representation_content">
                                        This strategy should allow you to meet your objectives and
                                        one day exit your business when it is best position for a
                                        sale. By selling without a clear and accurate strategy in
                                        place to maximize shareholder value, you will certainly
                                        leave money on the table.
                                        <br />
                                        <br />
                                        <span className="first_letter_text">A</span>xis M&A is here
                                        to guide you through the development of an appropriate and
                                        profitable selling or divestment strategy. It is critical
                                        that a forward-looking strategy is developed in view of what
                                        you are trying to accomplish. Axis M&A is able to integrate
                                        the dynamics specific to your particular company and to its
                                        industry by developing a set of benchmarks and initiatives
                                        to achieve a particular future position. Executing on all
                                        action items will ensure Axis M&A is putting the company in
                                        the best light for exit. <br />
                                        <br />
                                        Axis M&A is comprised of consulting experts, specialized in
                                        turnaround and exit strategies. Our strategy development
                                        process is based on creating value for the shareholders of
                                        our client companies. If you are in need of an expertly
                                        developed exit strategy, contact
                                        <a className="text-dark" href="/about-us/">
                                            {' '}
                                            Axis M&A
                                        </a>{' '}
                                        for a consultation.
                                        <br />
                                        <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExitStrategies;

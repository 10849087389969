// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-outline {
    border: 1px solid #ff5800 !important;
    background-color: #ff5800 !important;
    font-size: 22px !important;
    padding: 15px 40px !important;
    font-weight: 300;
    text-align: center;
    color: #fff !important;
    text-transform: none !important;
    font-family: inherit !important;
    border-radius: 60px !important;
    /* margin-top:50px !important; */
    margin-bottom: 5px!important;
}
@media (max-width: 575.98px) {
    .button-outline {
        font-size: 18px!important;
        margin-bottom: 20px!important;
    }
 }

`, "",{"version":3,"sources":["webpack://./src/assets/styles/css/components/button.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,oCAAoC;IACpC,0BAA0B;IAC1B,6BAA6B;IAC7B,gBAAgB;IAChB,kBAAkB;IAClB,sBAAsB;IACtB,+BAA+B;IAC/B,+BAA+B;IAC/B,8BAA8B;IAC9B,gCAAgC;IAChC,4BAA4B;AAChC;AACA;IACI;QACI,yBAAyB;QACzB,6BAA6B;IACjC;CACH","sourcesContent":[".button-outline {\n    border: 1px solid #ff5800 !important;\n    background-color: #ff5800 !important;\n    font-size: 22px !important;\n    padding: 15px 40px !important;\n    font-weight: 300;\n    text-align: center;\n    color: #fff !important;\n    text-transform: none !important;\n    font-family: inherit !important;\n    border-radius: 60px !important;\n    /* margin-top:50px !important; */\n    margin-bottom: 5px!important;\n}\n@media (max-width: 575.98px) {\n    .button-outline {\n        font-size: 18px!important;\n        margin-bottom: 20px!important;\n    }\n }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { Link } from 'react-router-dom';

import bgImage from '../../assets/images/login-bg.jpg';
import logoLight from '../../assets/images/logo.png';
import '../../assets/styles/css/pages/auth/index.css';

const Layout = ({ children }) => {
    return (
        <div className="login-container">
            <img src={bgImage} alt="login-bg" className="login-bg-image" />
            <div className="color-overlay" />
            <div className="logo-container">
                <Link to="/" className="home-link">
                    <img src={logoLight} alt="logo-light" className="login-logo" />
                </Link>
            </div>
            <div className="form-container">{children}</div>
        </div>
    );
};

export default Layout;

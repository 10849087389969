import React, { useState, useCallback, useEffect } from 'react';

const LazyImage = ({ placeholderImg, src, ...props }) => {
    const [imgSrc, setSrc] = useState(placeholderImg || src);

    const onLoad = useCallback(() => {
        setSrc(src);
    }, [src]);

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.addEventListener('load', onLoad);

        return () => {
            img.removeEventListener('load', onLoad);
        };
    }, [src, onLoad]);

    return <img {...props} alt={props.alt} title={props.title} src={imgSrc} />;
};

export default LazyImage;

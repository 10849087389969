import React from 'react';
import { Helmet } from 'react-helmet';
// import { Link } from 'react-router-dom';
// import { ChevronLeft } from 'react-feather';

import BuySideRep from './buySideRep';

import SellSideRep from './sellSideRep';
import Footer from '../../components/footer';
import SimpleSlider from './ourServiceSLide';
import '../../assets/styles/css/pages/services/services.css';

const Services = () => {
    return (
        <>
            <Helmet>
                
                <title>Merger & Acquisition Services | IT Services Mergers and Acquisitions | Axis M & A</title>
                <meta name="description" content="Axis M&A’s expertise in particularly valuable with buy-side transactions, sell-side M&A transactions, M&A Marketplace. Axis M&A team of professionals has a large network of advisors & experts in multiple segments." />
                <meta name="keywords" content="Merger & Acquisition Services, IT Services Mergers and Acquisitions, mergers and acquisitions experts, mergers and acquisitions services, m & a consultants, m & a experts, m and a consulting" />
                <link rel="canonical" href="https://www.axisma.com/services/"/>
            </Helmet>

             <div className="ourProcess">
        <div className="container-fluid">
        <SimpleSlider />
        </div>
            <div className="container">
            
                <BuySideRep />

                <SellSideRep />

                
            </div>

        </div>
            <Footer />
        </>
    );
};

export default Services;

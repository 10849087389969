import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {
    TextField,
    Select,
    MuiThemeProvider,
    createMuiTheme,
    MenuItem,
    FormControl,
    InputLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormHelperText
} from '@material-ui/core';


// import { createStyles } from '@material-ui/core/styles';

const bookingFormThemeOptions = {
    overrides: {
        MuiTextField: {
            root: {
                 width: '382px',
                 textTransform: 'none',
                 paddingBottom: '18px'
            }
        },
        MuiInputLabel: {
            root: {
                fontSize: '20px',
                color: '#333',
                 marginLeft: '10px',
                 top: '-25px !important'
            }
        },
        MuiInput: {
            root: {
                 width: '360px',
                 textTransform: 'none',
                 paddingBottom: '18px',
                 paddingTop: '12px',
                 padding: '10px'
            },
            input: {
                 color: '#333',
                 fontSize: '20px',
                 paddingLeft: '18px',
                 background: '#f5f5f5'
            },
            underline: {
                '&:before': {
                    borderBottom: '1px solid #333',
                    borderLeft: '1px solid #333',
                    borderRight: '1px solid #333',
                    bottom: '5px'
                },
                '&:hover': {
                    '&:not([disabled])': {
                        '&:before': {
                             borderBottom: '1px solid #333'
                        }
                    }
                }
            }
        },
        MuiSelect: {
            icon: {
                color: '#333'
            }
        },
        MuiFormHelperText: {
            root: {
                 color: '#333',
                 fontSize: '15px'
            }
        },
        MuiFormControl: {
            root: {
                 paddingBottom: '18px'             
            }
        }
    },
    palette: {
        primary: {
             main: '#fff'
        },
        secondary: {
             main: '#000'
        },
        error: {
             main: '#f44242'
        }
    },
    typography: {
        useNextVariants: true,
        fontFamily: 'Gill Sans'
    }
};

const authFormThemeOptions = {
    overrides: {
        MuiTextField: {
            root: {
                textTransform: 'none'
            }
        },
        MuiInputLabel: {
            root: {
                fontSize: '16px',
                 color: '#4F63AF !important',
                 top: '-40px'
            },
            shrink: {
                 color: '#4F63AF'
            }
        },
        MuiInput: {
            root: {
                textTransform: 'none'
            },
            input: {
                 color: '#333',
                 fontSize: '16px',
                 fontWeight: 600
            },
            underline: {
                '&:before': {
                     borderBottom: '1px solid #9A9A9A'
                },
                '&:after': {
                     borderBottom: '1px solid #9A9A9A'
                },
                '&:hover': {
                    '&:not([disabled])': {
                        '&:before': {
                             borderBottom: '1px solid #9A9A9A'
                        }
                    }
                }
            }
        },
        MuiFormHelperText: {
            root: {
                 color: '#cf4949',
                fontSize: '15px'
            }
        }
    },
    palette: {
        primary: {
            main: '#000'
        },
        secondary: {
            main: '#000'
        },
        error: {
            main: '#f44242'
        }
    },
    typography: {
        useNextVariants: true,
        fontFamily: 'Gill Sans'
    }
};

// const useStyles = createStyles({});

const InputField = ({
    variant,
    type,
    inputType,
    label,
    placeholder,
    options,
    defaultValue,
    value,
    children,
    onClick,
    onChange,
    helperText,
    checked,
    className,
    style
}) => {
    const getThemeOptions = () => {
        if (type === 'authForm') {
            return authFormThemeOptions;
        } else {
            return bookingFormThemeOptions;
        }
    };

    return (
        <MuiThemeProvider theme={createMuiTheme(getThemeOptions())}>
            {variant === 'checkbox' ? (
                <FormGroup className={className}>
                    <FormControlLabel
                        control={<Checkbox checked={checked} onChange={onChange} />}
                        label={label}
                    />
                    {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
                </FormGroup>
            ) : variant === 'select' ? (
                <FormControl className={className}>
                    <InputLabel>{label}</InputLabel>
                    <Select
                        defaultValue={defaultValue}
                        value={value || ''}
                        onChange={onChange}
                        onClick={onClick}
                        style={style}
                    >
                        {options && options.length
                            ? options.map((option, index) => (
                                  <MenuItem value={option.value} key={`select-option-${index}`}>
                                      {option.display}
                                  </MenuItem>
                              ))
                            : children}
                    </Select>
                    {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
                </FormControl>
            ) : (
                <TextField
                    type={inputType}
                    label={label}
                    placeholder={placeholder}
                    onClick={onClick}
                    onChange={onChange}
                    className={className}
                    style={style}
                    helperText={helperText}
                />

            )}
        </MuiThemeProvider>
    );
};

export default InputField;

import React from 'react';
import { Link } from 'react-router-dom';

import { isUserAuthenticated } from '../../lib/authUtils';

import '../../assets/styles/css/common/sideBar.css';





const SideBar = ({ isMenuOpen, onClickMenuItems }) => {
    
    return (
        <div className={`overlay ${isMenuOpen ? 'selected' : 'dismiss'}`}>
            <div className="overlay-content">
                <Link onClick={onClickMenuItems} to="/">
                    Home
                </Link>
                {isUserAuthenticated() ? (
                    <Link onClick={onClickMenuItems} to="/search/">
                        Search
                    </Link>
                ) : null}
                <Link onClick={onClickMenuItems} to="/services/">
                    Our Services
                </Link>
                <Link onClick={onClickMenuItems} to="/our-process/">
                    Our Process
                </Link>
                <Link onClick={onClickMenuItems} to="/about-us/">
                    About Us
                </Link>
                <Link onClick={onClickMenuItems} to="/WhyAxis/">
                    Why Axis M&A?
                </Link> 
                <Link onClick={onClickMenuItems} to="/resources/">
                    Resources Library
                </Link>               
                {isUserAuthenticated() ? (
                    <Link onClick={(event) => onClickMenuItems(event, true)}>Logout</Link>
                ) : null}
                {!isUserAuthenticated() ? (
                    <>                                        
                        <Link onClick={onClickMenuItems} to="/auth/register/buyer/">
                            Sign Up
                        </Link>
                        <Link onClick={onClickMenuItems} to="/auth/login/buyer/">
                            Login
                        </Link>
                        
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default SideBar;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-container {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999999999;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
}

.spinner {
    animation: rotation 1s infinite linear;
    position: absolute;
    font-size: 10px;
    -webkit-animation: rotation 1.1s infinite linear;
}

.spinner,
.spinner:after {
    border-radius: 50%;
    width: 8em;
    height: 8em;
    display: block;
    position: absolute;
    top: 45%;
    margin-top: -4.05em;
    left: 45%;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/css/components/spinner.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,QAAQ;IACR,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,oCAAoC;AACxC;;AAEA;IACI,sCAAsC;IACtC,kBAAkB;IAClB,eAAe;IACf,gDAAgD;AACpD;;AAEA;;IAEI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,cAAc;IACd,kBAAkB;IAClB,QAAQ;IACR,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".spinner-container {\n    position: fixed;\n    left: 0px;\n    top: 0px;\n    width: 100%;\n    height: 100%;\n    z-index: 9999999999;\n    overflow: hidden;\n    background-color: rgba(0, 0, 0, 0.5);\n}\n\n.spinner {\n    animation: rotation 1s infinite linear;\n    position: absolute;\n    font-size: 10px;\n    -webkit-animation: rotation 1.1s infinite linear;\n}\n\n.spinner,\n.spinner:after {\n    border-radius: 50%;\n    width: 8em;\n    height: 8em;\n    display: block;\n    position: absolute;\n    top: 45%;\n    margin-top: -4.05em;\n    left: 45%;\n}\n\n@keyframes rotation {\n    from {\n        transform: rotate(0deg);\n    }\n    to {\n        transform: rotate(359deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { TRIGGER_MAIL, TRIGGER_MAIL_FAILED, TRIGGER_MAIL_SUCCESS } from './constants';

const INIT_STATE = {
    loading: false,
    mail: null
};

const Common = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TRIGGER_MAIL:
            return { ...state, loading: false };
        case TRIGGER_MAIL_SUCCESS:
            return { ...state, mail: action.payload, loading: false, error: null };
        case TRIGGER_MAIL_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
};

export default Common;

import React from 'react';
import marketplace from '../../assets/images/marketplace.jpg';
import marketplacePoster from '../../assets/images/poster/marketplace.jpg';
import LazyImage from '../../components/lazyImage';

import sellside from '../../assets/images/sell-side.jpg';
import sellSideRepPoster from '../../assets/images/poster/sell-side.jpg';

import '../../assets/styles/css/pages/services/services.css';
import uiData from './sellSideData.json';

const SellSideRep = () => {
    return (
        <div id="sellSide" className="services-container">
            <div className="row">
                <div className="col-md-12 col-lg-8 d-flex ">
                    <div className="card bg-grey flex-fill">
                        <div className="card-header">
                            <h2 className="buy_side_header">Sell-Side Representation </h2>
                        </div>
                        <LazyImage
                            className="sell_side_representation_image"
                            src={sellside}
                            placeholderImg={sellSideRepPoster}
                            alt="sell_side_representation_image"
                        />
                        <div className="card-body">
                            <p className="buy_side_representation_content">
                                <span className="first_letter_text">T</span>he Axis M&A team
                                specializes in sell-side
                                <a className="text-dark" href="/">
                                    {' '}
                                    M&A transactions{' '}
                                </a>
                                in the technology and staffing sectors. Due to our leaders’
                                experience in these sectors, we are able to bring focused, expert
                                level advice and intermediary consulting to each client. The Founder
                                of Axis M&A owns and operates a successful staffing company, putting
                                them in a unique position to understand the intricacies of a
                                staffing company sale.
                            </p>
                            <p className="buy_side_representation_content">
                                The Axis M&A team of professionals has a large network of advisors
                                and experts in multiple segments that are leveraged in bringing the
                                stickiest of deals to close. These include tax, due diligence, and
                                finance professionals; attorneys (buyers, seller and transactional);
                                post-sale transition and operational teams. Other keys to our
                                success, include the following processes:
                            </p>
                            <ul className="buy-side-ul">
                                {uiData.map((items) => {
                                    return <li>{items.content}</li>;
                                })}
                            </ul>
                            <p className="buy_side_representation_content">
                                We pride ourselves in completing comprehensive preparation before we
                                go to market. This helps us ensure that the value of your company is
                                highlighted to potential buyers as accurately as possible.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-lg-4 img-container d-flex">
                    <div className="card flex-fill">
                        <div className="card-body p-0">
                            <h2 className="buy_side_header mb-3">Marketplace</h2>
                            <LazyImage
                                className="sell_side_representation_image  market-img img-fluid"
                                src={marketplace}
                                placeholderImg={marketplacePoster}
                                alt="marketplace_image"
                            />

                            <p className="buy_side_representation_content">
                                <span className="first_letter_text">A</span>xis M&A is proud to be
                                an industry leader in M&A innovation. The launch of Axis M&A
                                Marketplace will mark a disruption in traditional{' '}
                                <a className="text-dark" href="/">
                                    M&A consulting.
                                </a>{' '}
                                This unique tool will foster a transparent approach to buying or
                                selling a company. Axis M&A Marketplace will allow for company
                                stakeholders, interested in selling, to create a company profile and
                                list themselves on the marketplace for potential buyers to browse.
                                Buyers are now able to browse a database of new company listings and
                                be notified when a company fitting their specific wants hits the
                                market. The Axis M&A Marketplace will protect company identity all
                                while providing more transparency to the M&A world than ever before.{' '}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SellSideRep;

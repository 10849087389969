// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-horizon {
    color: #5f8fa1;
}

.bg-wildsand {
    background-color: #f6f6f6;
}

.bg-catskill-white {
    background-color: #e5f0f4;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/css/common/colors.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".text-horizon {\n    color: #5f8fa1;\n}\n\n.bg-wildsand {\n    background-color: #f6f6f6;\n}\n\n.bg-catskill-white {\n    background-color: #e5f0f4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { isUserAuthenticated, getLoggedInUser } from '../lib/authUtils';
import utils from '../lib/utils';

// auth
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import Confirm from '../pages/auth/Confirm';
// import ForgetPassword from '../pages/auth/ForgetPassword';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';


// error
import Error404 from '../pages/errors/Error404';
import Error500 from '../pages/errors/Error500';

// home
import Home from '../pages/home/index';

//company
import Company from '../pages/company/index';
import About from '../pages/about/index';
import OurWork from '../pages/ourWork/index';
import Services from '../pages/services/index';
import CompanyView from '../pages/company/companyView';
import LandingPage from '../pages/company/landingPage';
import WhyAxis from '../pages/WhyAxis/WhyAxisM&A';
import Resources from '../pages/resources/resources-library';

// handle auth
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return (
                    <Redirect
                        to={{ pathname: '/auth/login/buyer', state: { from: props.location } }}
                    />
                );
            }

            const user = getLoggedInUser();
            const userRole = utils.getUserRole(user);
            // check if route is restricted by role
            if (roles && userRole && roles.indexOf(userRole) === -1) {
                // role not authorized so redirect to home page
                return <Redirect to={{ pathname: '/auth/login/buyer' }} />;
            }

            // authorized so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/" />
};

// auth routes
const authRoutes = {
    path: '/auth',
    name: 'Auth',
    children: [
        {
            path: '/auth/login/:role',
            name: 'Login',
            component: () => <Login />
        },
        {
            path: '/auth/register/:role',
            name: 'Register',
            component: () => <Register />
        },
        {
            path: '/auth/confirm',
            name: 'Confirm',
            component: () => <Confirm />
        },
        {
            path: '/auth/linkedin/callback',
            name: 'Linked In Callback',
            component: () => <LinkedInPopUp />
        }
        // {
        //     path: '/auth/forgetPassword',
        //     name: 'Forget Password',
        //     component: () => <ForgetPassword />
        // }
    ]
};

// error routes
const errorRoutes = {
    path: '/error',
    name: 'Errors',
    children: [
        {
            path: '/error/404',
            exact: true,
            name: 'Error 404 - Page Not Fount',
            component: Error404
        },
        {
            path: '/error/500',
            exact: true,
            name: 'Error 500 - Internal Server Error',
            component: Error500
        }
    ]
};

// home route
const homeRoute = {
    path: '/',
    children: [
        {
            path: '/',
            exact: true,
            name: 'Home',
            component: () => <Home />
        },
        {
            path: '/about-us/',
            exact: true,
            name: 'About Us',
            component: () => <About />
        },
        {
            path: '/our-process/',
            exact: true,
            name: 'Our Process',
            component: () => <OurWork />
        },
        {
            path: '/services/',
            exact: true,
            name: 'Services',
            component: () => <Services />
        },
        {
            path: '/WhyAxis/',
            exact: true,
            name: 'WhyAxis',
            component: () => <WhyAxis />
        },
        {
            path: '/resources/',
            exact: true,
            name: 'Resources Library',
            component: () => <Resources />
        }
    ]
};
 

// search route
const searchRoute = {
    path: '/search',
    name: 'Search',
    children: [
        {
            path: '/search/',
            exact: true,
            name: 'Search',
            component: () => <LandingPage />,
            route: PrivateRoute
        },
        {
            path: '/search/company/',
            exact: true,
            name: 'Search Company',
            component: () => <Company />,
            route: PrivateRoute
        }
    ]
};

// company route
const companyRoutes = {
    path: '/company/',
    name: 'Company',
    children: [
        {
            path: '/company/:id',
            name: 'Company Profile',
            component: () => <CompanyView />,
            route: PrivateRoute
        }
    ]
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [rootRoute, authRoutes, errorRoutes, homeRoute, searchRoute, companyRoutes];

const authProtectedRoutes = [searchRoute, companyRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };

import React from 'react';
import { Helmet } from 'react-helmet';

import VideoContainer from './videoContainer';
import Services from './services';
import Job from './job';
import BookAppoinment from './bookAppoinment';
import Testimonials from './testimonials';
import Footer from './footer';

export default class Home extends React.Component {
    render() {
        return (
            <>
            <Helmet>
                <title>Mergers & Acquisitions | M & A Consulting | M & A Marketplace | Axis M & A</title>
                <meta name="description" content="Mergers and Acquisitions Services is focused on serving mergers and acquisitions advisors, recent m&a deals, m & a consulting, m & a marketplace, merger deals." />
                <meta name="keywords" content="Mergers & Acquisitions, M & A Consulting, M & A Marketplace, m&a deals, m & a consulting, m&a company, mergers and acquisitions companies, m&a mergers and acquisitions" />
                <link rel="canonical" href="https://www.axisma.com/"/>
            </Helmet>

                <VideoContainer />
                <Services />
                <Job />
                <BookAppoinment />
                <Testimonials />
                <Footer />
            </>
        );
    }
}

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Alert } from '@material-ui/lab';

import { registerUser, thirdPartyAuth } from '../../redux/actions';
import { isUserAuthenticated } from '../../lib/authUtils';
import InputField from '../../components/inputField';
import LinkedIn from '../../components/linkedInBtn';
// import FacebookBtn from '../../components/facebookBtn';
import GoogleBtn from '../../components/googleBtn';
import utils from '../../lib/utils';
import Layout from './layout';
import Spinner from '../../components/spinner';

import '../../assets/styles/css/pages/auth/index.css';

class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                firstName: null,
                lastName: null,
                phone: null,
                email: null,
                password: null,
                confirmedPassword: null,
                termsAndConditions: false
            },
            errorMessage: {
                firstName: false,
                lastName: false,
                phone: false,
                email: false,
                password: false,
                confirmedPassword: false,
                termsAndConditions: false,
                passwordMatch: false
            }
        };

        this.role = utils.getRoleFromUrl(this.props);
        this.isBuyer = utils.isBuyer(this.role);
    }

    /**
     * Handles the submit
     */
    handleValidSubmit = (event) => {
        event.preventDefault();
        const { errorMessage, form } = this.state;
        const {
            firstName,
            lastName,
            phone,
            email,
            password,
            confirmedPassword,
            termsAndConditions
        } = form;

        if (
            firstName &&
            lastName &&
            phone &&
            email &&
            password &&
            confirmedPassword &&
            termsAndConditions &&
            password === confirmedPassword
        ) {
            this.props.registerUser(
                firstName,
                lastName,
                phone,
                email,
                password,
                this.isBuyer ? 'isBuyer' : 'isSeller'
            );
        } else {
            const errMsg = JSON.parse(JSON.stringify(errorMessage));
            if (!firstName) {
                errMsg.firstName = true;
                this.setState({ errorMessage: errMsg });
            }
            if (!lastName) {
                errMsg.lastName = true;
                this.setState({ errorMessage: errMsg });
            }
            if (!phone) {
                errMsg.phone = true;
                this.setState({ errorMessage: errMsg });
            }
            if (!email) {
                errMsg.email = true;
                this.setState({ errorMessage: errMsg });
            }
            if (!password) {
                errMsg.password = true;
                this.setState({ errorMessage: errMsg });
            }
            if (!termsAndConditions) {
                errMsg.termsAndConditions = true;
                this.setState({ errorMessage: errMsg });
            }
            if (!confirmedPassword) {
                errMsg.confirmedPassword = true;
                this.setState({ errorMessage: errMsg });
            }
            if (password !== confirmedPassword) {
                errMsg.passwordMatch = true;
                this.setState({ errorMessage: errMsg });
            }
        }
    };

    /**
     * Redirect to root
     */
    redirectToRoot = () => {
        if (isUserAuthenticated()) {
            return <Redirect to="/" />;
        }
    };

    /**
     * Redirect to confirm
     */
    redirectToConfirm = () => {
        if (this.props.user && !this.props.user.isVerified) {
            return <Redirect to={`/auth/confirm/${this.role}`} />;
        }
    };

    handleOnChange = (event, formStateField) => {
        const { form, errorMessage } = this.state;
        form[formStateField] = event.target.value;
        errorMessage[formStateField] = false;

        if (formStateField === 'confirmedPassword') {
            errorMessage['passwordMatch'] = false;
        }

        this.setState({ form, errorMessage });
    };

    handleCheckBoxChange = (event, formStateField) => {
        const { form, errorMessage } = this.state;
        form[formStateField] = event.target.checked;
        errorMessage[formStateField] = false;

        this.setState({ form, errorMessage });
    };

    showError = (formStateField) => {
        return this.state.form[formStateField] === '';
    };

    onSuccessGoogleAuth = (response) => {
        console.log(response);
        this.props.thirdPartyAuth(response, 'google', this.isBuyer ? 'isBuyer' : 'isSeller');
    };

    // fbCallback = (response) => {
    //     if (response.email) {
    //         this.props.thirdPartyAuth(response, 'facebook', this.isBuyer ? 'isBuyer' : 'isSeller');
    //     }
    // };

    onSuccessLinkedInAuth = (response) => {
        console.log(response);
        // this.props.thirdPartyAuth(response, 'linkedIn', this.isBuyer ? 'isBuyer' : 'isSeller');
    };

    onFailureAuth = (response) => {
        console.log(response);
    };

    getPasswordHelperText = () => {
        const { form, errorMessage } = this.state;

        if (errorMessage.confirmedPassword || form.confirmedPassword === '') {
            return 'ⓘ Confirm Password is required';
        }

        if (errorMessage.passwordMatch) {
            return "ⓘ Passsword doesn't match";
        }
    };

    render() {
        const isAuthTokenValid = isUserAuthenticated();
        const { form, errorMessage } = this.state;

        return (
            <>
                {this.props.loading ? <Spinner /> : null}
                {this.redirectToConfirm()}
                {this.redirectToRoot()}
                {!isAuthTokenValid ? (
                    <>
                     <Helmet>
                
                     <link rel="canonical" href="https://www.axisma.com/auth/register/buyer/"/>
                    </Helmet>
                        <Layout>
                            <div className="header-text">
                                Create {this.isBuyer ? 'Buyer' : 'Seller'} Account
                            </div>
                            <form
                                className="login-form"
                                autoComplete="off"
                                onSubmit={(event) => this.handleValidSubmit(event)}
                            >
                                <div className="input-field-container">
                                    <InputField
                                        inputType="text"
                                        type="authForm"
                                        label="First Name"
                                        className="form-field sapce-between-field"
                                        value={form.firstName}
                                        onChange={(event) =>
                                            this.handleOnChange(event, 'firstName')
                                        }
                                        helperText={
                                            errorMessage.firstName || form.firstName === ''
                                                ? 'ⓘ First Name is required'
                                                : null
                                        }
                                    />

                                    <InputField
                                        inputType="text"
                                        type="authForm"
                                        label="Last Name"
                                        className="form-field"
                                        value={form.lastName}
                                        onChange={(event) => this.handleOnChange(event, 'lastName')}
                                        helperText={
                                            errorMessage.lastName || form.lastName === ''
                                                ? 'ⓘ Last Name is required'
                                                : null
                                        }
                                    />
                                </div>
                                <div className="input-field-container">
                                    <InputField
                                        inputType="number"
                                        type="authForm"
                                        label="Phone"
                                        className="form-field sapce-between-field"
                                        value={form.phone}
                                        onChange={(event) => this.handleOnChange(event, 'phone')}
                                        helperText={
                                            errorMessage.phone || form.phone === ''
                                                ? 'ⓘ Phone Number is required'
                                                : null
                                        }
                                    />
                                    <InputField
                                        inputType="email"
                                        type="authForm"
                                        label="Email"
                                        className="form-field"
                                        value={form.email}
                                        onChange={(event) => this.handleOnChange(event, 'email')}
                                        helperText={
                                            errorMessage.email || form.email === ''
                                                ? 'ⓘ Email is required'
                                                : null
                                        }
                                    />
                                </div>
                                <div className="input-field-container">
                                    <InputField
                                        inputType="password"
                                        type="authForm"
                                        label="Create Password"
                                        className="form-field sapce-between-field"
                                        value={form.password}
                                        onChange={(event) => this.handleOnChange(event, 'password')}
                                        helperText={
                                            errorMessage.password || form.password === ''
                                                ? 'ⓘ Password is required'
                                                : null
                                        }
                                    />
                                    <InputField
                                        inputType="password"
                                        type="authForm"
                                        label="Confirm Password"
                                        className="form-field"
                                        value={form.confirmedPassword}
                                        onChange={(event) =>
                                            this.handleOnChange(event, 'confirmedPassword')
                                        }
                                        helperText={this.getPasswordHelperText()}
                                    />
                                </div>

                                <InputField
                                    variant="checkbox"
                                    type="authForm"
                                    label="I agree to the Terms and Conditions"
                                    value={form.termsAndConditions}
                                    onChange={(event) =>
                                        this.handleCheckBoxChange(event, 'termsAndConditions')
                                    }
                                    helperText={
                                        errorMessage.termsAndConditions ||
                                            form.termsAndConditions === ''
                                            ? 'ⓘ Agree to Terms and Conditions'
                                            : null
                                    }
                                />

                                {this.props.error ? (
                                    <Alert severity="error">{this.props.error}</Alert>
                                ) : null}

                                <div className="signup-button-container">
                                    <button className={`signup-button`} type="submit">
                                        SIGN UP
                                    </button>
                                </div>
                            </form>

                            <div className="already-have-account-text">
                                Already have an account?{' '}
                                <Link
                                    to={`/auth/login/${this.role}`}
                                    className="already-have-account-text"
                                >
                                    Sign In
                                </Link>
                            </div>

                            <div className="signup-using-container">
                                <div className="signup-using">Or Sign Up Using
                                <div className="media-icon-container">                                    
                                     <LinkedIn                                        
                                        onSuccessLinkedInAuth={this.onSuccessLinkedInAuth}
                                        onFailureLinkedInAuth={this.onFailureAuth}
                                    /> 
                                    
                                    {/* <FacebookBtn fbCallback={this.fbCallback} /> */}
                                    <GoogleBtn
                                        onSuccessGoogleAuth={this.onSuccessGoogleAuth}
                                        onFailureGoogleAuth={this.onFailureAuth}
                                    />
                                </div>
                                </div>
                            </div>
                        </Layout>
                    </>
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { user, loading, error } = state.Auth;
    return { user, loading, error };
};

export default connect(mapStateToProps, { registerUser, thirdPartyAuth })(withRouter(Register));

import React from 'react';
import { Helmet } from 'react-helmet';

import SimpleSlider from './whyAximaSlide.js';
import '../../assets/styles/css/pages/WhyAxis/Whyaxis.css';
import BookAppoinment from '../home/bookAppoinment';
import LazyImage from '../../components/lazyImage';
import about from '../../assets/images/why-axima/img-2.jpg';
import aboutPoster from '../../assets/images/why-axima/img-2.jpg';
import Footer from '../../components/footer';
import sellProcess from '../../assets/images/why-axima/img-1.jpg';

const WhyAxis = () => {
    return (
        <>
       
            <Helmet>
                <title>Why Sell a Business With Axis M&A? | Benefits of Selling a Business</title>
                <meta name="description" content="Axis M&A was created to help businesses identify opportunities, and navigate their way through M&A transactions with unmatched efficiency." />
                <link rel="canonical" href="https://www.axisma.com/WhyAxis/"/>
                <meta name="keywords" content=" Benefits of selling a business, business broker, Financials, Marketing, Buyers, Negotiate, Firm to Partner" />
            </Helmet>
            <div className="ourProcess">
                <div className="container-fluid">
                    <SimpleSlider />
                </div>
            </div>
            <div className="container why-axima-container">
                
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card mb-2">
                            <div className="card-body pt-0">
                                <h1 className="Why" >Selling a Business</h1>
                                <h3 className="card-title">
                                    Why Sell a Business With Axis M&A?
                                </h3>
                                <p>Our portfolio of International, cash-rich buyers guarantees that Axis M&A can provide you access to an exclusive network when you sell your business. Upon joining Axis M&A Marketplace, you are instantly able to access the broadest number of qualified, potential buyers - something you just can’t get with typical<a className="text-dark" href="/"> M&A firms.</a> Instead of having access to your own local market, we connect you worldwide.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card mb-2">
                            <div className="card-body pt-0">
                                <h3 className="card-title">
                                 What Can Axis M&A Do For You?
                                </h3>
                                <p>Axis M&A was created to help businesses identify opportunities, and navigate their way through M&A transactions with unmatched efficiency. Our priorities consist of maximizing value, creating steady growth, and connecting you to the perfect match for your next <a className="text-dark" href="/"> merger or acquisition.</a> We rethought the typical way a business is bought or sold, and created a new company that would disrupt the way private companies are sold. While other M&A firms rely solely on their professional relationships, Axis M&A uses proprietary marketing tools to reach the largest pool of qualified buyers and sellers.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8">
                        <div className="card mb-2">
                            <div className="card-body pt-0">
                                <h3 className="card-title">
                                    {/* Why Sell a Business With Axis M&A? */}
                                    What are the benefits of selling a business with Axis M&A?
                                </h3>
                                <p>Axis M&A specializes in the sale of businesses,Our team leverages combined decades of experience representing the sale of firms. We use the same care in finding a qualified buyer for your business that we would if we were selling our own since we have all been successful business owners and/or managers prior to joining the firm. Selling your business with Axis M&A will minimize the time it takes to sell your company.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <LazyImage
                                    className="WhyAxis-img img-fluid"
                                    src={about}
                                    alt=" company acquisitions"
                                    title=" company acquisitions"
                                    placeholderImg={aboutPoster}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card mb-2">
                            <div className="card-body pt-0">
                                <h3 className="card-title mb-4">
                                    What is a business broker and why do I need one?
                                </h3>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h4 className="list_heading">-Service and Expertise</h4>
                                        <ul>
                                            <li>
                                                Determine an accurate valuation and market value</li>
                                            <li>

                                                Create in-depth presentations highlighting the business to be sold</li>
                                            <li>

                                                Market to prospective buyers to protect seller confidentiality</li>
                                            <li>

                                                Assist the seller in negotiating offers and the terms of the transaction</li>
                                            <li>

                                                Facilitate the transaction in conjunction with the seller’s tax and legal counsel
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6">
                                    <h4 className="list_heading">-Identifying The Buyers</h4>
                                        <ul>
                                            <li>Corporate executives</li>

                                            <li>Small group buyers</li>

                                            <li>Privateequitygroups</li>

                                            <li>Industry companies</li>

                                            <li>Synergistic buyers</li>

                                            <li>High net worth buyers
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="why-axima-selling-process">
                <div className="container">
                <div className="card bg-grey">
                            <div className="card-body">

                    <h3 className="card-title mb-4">
                    8 Step Business Selling Process
                    </h3>
                    <div className="row">
                        <div className="col-lg-8">
                            <ol>
                                <li>Non-disclosure <span className="text-orange">&#124;</span> Execute a Non-Disclosure Agreement from Axis M&A to the owner</li>

                                <li>Financials <span className="text-orange">&#124;</span> Gather and examine company historical and financial data</li>

                                <li>Terms <span className="text-orange">&#124;</span> Determine fair market value and terms</li>

                                <li>Agreement <span className="text-orange">&#124;</span> Enter into an engagement agreement with the owner</li>

                                <li>Marketing <span className="text-orange">&#124;</span> Execute marketing plan</li>

                                <li>Buyers <span className="text-orange">&#124;</span> Qualify Buyers</li>

                                <li>Negotiate <span className="text-orange">&#124;</span> Negotiate offers</li>

                                <li>Sign <span className="text-orange">&#124;</span> Close the transaction</li>
                            </ol>
                        </div>
                        <div className="col-lg-4">
                        <LazyImage
                            className="WhyAxis-img-sell img-fluid"
                            src={sellProcess}
                            alt="m & a consultants"
                            title="m & a consultants"
                            placeholderImg={sellProcess}
                        />
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
           <div className="container why-axima-container">
           <div className="card mb-0">
             <div className="card-body py-0">
           <h1 className="Why" >Buying a Business</h1>
           </div>
           </div>
           <div className="row">
                    <div className="col-lg-8">
                        <div className="card mb-2">
                            <div className="card-body pt-0">
                                <h3 className="card-title">
                                Why Buy a Business with Axis M&A?
                                </h3>
                                <p>Axis M&A has access to companies,and relationships with business owners around the globe looking to sell or divest in their current firm Our experienced <a className="text-dark" href="/">M&A Consultants</a> and proprietary Axis M&A Marketplace processes are leveraged to produce industry-leading transaction times and ultimately cut down on deal fatigue. We can help you buy a business at ease with an experienced professional guiding you every step of the way.</p>

                                <p>Through our rich, global network affiliations, we have exclusive access to worldwide sales listings many of which are never advertised publicly.  We help to facilitate a targeted buyout within your budget from amongst a broad spectrum of qualified candidates.  </p>
<p>Axis M&A can help you uncover numerous hidden opportunities to buy a business with the reassurance that you found the best options for your inorganic growth objectives.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card mb-2">
                            <div className="card-body pt-0">
                                <h3 className="card-title">
                                Why is Axis M&A the Best Firm to Partner with?
                                </h3>
                                <p>Axis M&A is confident that our portfolio of international sellers will contain the perfect match when you are looking to execute an acquisition. Axis M&A Marketplace allows you to take advantage of an ever-growing network of verified sellers and promote unmatched visibility and speed through the entire deal-cycle.</p>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
            <BookAppoinment removeLine className="WhyFooter" />
            <Footer className="mt-0"/>
        </>
    );
}
export default WhyAxis;
import React from 'react';
import { Helmet } from 'react-helmet';

// import { Link } from 'react-router-dom';
// import { ChevronLeft } from 'react-feather';
import SimpleSlider from './ourProcessSLide.js';
import Process from './ourProcess';
import Valuations from './valuations';
import Exit from './exitStrategies';
import BookAppoinment from '../home/bookAppoinment';
import Footer from '../../components/footer';

import '../../assets/styles/css/pages/services/services.css';

const OurWork = () => {
    return (
        <>
            <Helmet>
                <title>Mergers and Acquisitions - Merger And Acquisition Process | Axis M&A</title>
                <meta
                    name="description"
                    content="Axis M&A - To help you understand this process, in this article we provide an overview of the typical stages in an M&A transaction. 1.Buy Side, 2.Sell-Side, 3.Valuations and, 4.Exit Strategies."
                />
                <meta
                    name="keywords"
                    content="Mergers and Acquisitions (M&A), buying process, M&A consultation, M&A Valuations, network of buyers, sell-side process, buyers and sellers"
                />
                <link rel="canonical" href="https://www.axisma.com/our-process/" />
            </Helmet>
            <div className="ourProcess">
                <div className="container-fluid">
                    <SimpleSlider />
                </div>
                <div className="container">
                    <div className="service-title">
                        <h1 className="ourservices_header">Our Process</h1>
                    </div>
                    <Process />
                    <Valuations />
                    <BookAppoinment removeLine />
                    <Exit />
                </div>
            </div>
            <Footer />
        </>
    );
};

export default OurWork;

import React from 'react';

import Search from '../../components/search';
import CompanyFilter from './companyFilter';
import CompanyList from './companyList';
import utils from '../../lib/utils';

import './../../assets/styles/css/pages/company/index.css';

import companyData from '../../assets/data/companyList.json';

const now = new Date();

const configData = {
    checkBoxList: [
        { id: 1, label: 'Location', value: 'city', type: 'text' },
        { id: 2, label: 'Business Type', value: 'businessType', type: 'text' },
        { id: 3, label: 'Revenue', value: 'revenue', type: 'number', min: 0, max: 100 },
        { id: 4, label: 'Owned By', value: 'ownedBy', type: 'text' },
        { id: 5, label: 'Size', value: 'totalEmployees', type: 'number', min: 0, max: 100 },
        { id: 6, label: 'Company Age', value: 'companyAge', type: 'number', min: 0, max: 100 }
    ],
    sortList: [
        { label: 'Company Name', value: 'companyName' },
        { label: 'Popularity', value: 'popularity' },
        { label: 'Location', value: 'city' }
    ]
};

export default class Company extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updatedCompanyData: [],
            subFilters: {}
        };
    }

    componentDidMount() {
        this.setCompanyDataToState();
    }

    setCompanyDataToState = () => {
        this.setState({ updatedCompanyData: this.formCompanyList(companyData) }, () =>
            this.mapTresholdValues()
        );
    };

    mapTresholdValues = () => {
        const { updatedCompanyData } = this.state;

        configData.checkBoxList.map((checkBoxList) => {
            if (checkBoxList.type === 'number') {
                const array = updatedCompanyData.map((item) => {
                    return Math.floor(+item[checkBoxList.value]);
                });

                checkBoxList.min = Math.min(...array);
                checkBoxList.max = Math.max(...array);
            }

            return checkBoxList;
        });
    };

    resetData = (mainFilterValue) => {
        let { subFilters } = this.state;

        if (subFilters[mainFilterValue]) {
            delete subFilters[mainFilterValue];
        }

        this.updateFilterState();
    };

    updateFilterState = () => {
        const { subFilters } = this.state;

        let result = [];
        const subFilterKeys = Object.keys(subFilters);

        if (subFilterKeys && subFilterKeys.length) {
            subFilterKeys.forEach((filter, index) => {
                this.formCompanyList(companyData).forEach((dataObj, index) => {
                    if (subFilters[filter].includes(dataObj[filter])) {
                        result.push(dataObj);
                    }
                });
            });
        } else {
            result = this.formCompanyList(companyData);
        }

        result = utils.removeArrayDuplicates(result);
        this.setState({ subFilters, updatedCompanyData: result });
    };

    formCompanyList = (data) => {
        return data.map(
            ({
                sellerID,
                businessDetails: {
                    companyName,
                    totalEmployees,
                    establishedYear,
                    revenue,
                    businessType,
                    companyAddress: { country, city },
                    popularity,
                    ownedBy
                }
            }) => {
                const companyAge = now.getFullYear() - utils.parseDate(establishedYear).year;
                return {
                    ownedBy,
                    companyName,
                    country,
                    city,
                    totalEmployees,
                    companyAge,
                    revenue,
                    businessType,
                    popularity,
                    sellerID
                };
            }
        );
    };

    handleFilterChange = (event, mainFilterValue, subFilterValue) => {
        let { subFilters, updatedCompanyData } = this.state;

        if (!updatedCompanyData.length) {
            updatedCompanyData = this.formCompanyList(companyData);
        }

        if (event.target.checked) {
            if (!subFilters[mainFilterValue]) {
                subFilters[mainFilterValue] = [];
            }
            subFilters[mainFilterValue].push(subFilterValue);
        } else {
            if (subFilters[mainFilterValue] && subFilters[mainFilterValue].length) {
                if (subFilters[mainFilterValue].length === 1) {
                    delete subFilters[mainFilterValue];
                } else {
                    subFilters[mainFilterValue].splice(
                        subFilters[mainFilterValue].indexOf(subFilterValue),
                        1
                    );
                }
            }
        }

        this.updateFilterState();
    };

    handleSliderChange = (newValue, filterKey) => {
        const result = this.formCompanyList(companyData).filter((companyData) => {
            return companyData[filterKey] <= newValue;
        });

        this.setState({ updatedCompanyData: result });
    };

    handleSort = (event) => {
        let { updatedCompanyData } = this.state;

        const value = event.target.value;
        const dataToSort =
            updatedCompanyData && updatedCompanyData.length
                ? updatedCompanyData
                : this.formCompanyList(companyData);

        updatedCompanyData = dataToSort.sort((a, b) => {
            if (+a[value]) {
                return b[value] - a[value];
            } else {
                return (a[value] || '')
                    .toString()
                    .localeCompare((b[event.target.value] || '').toString());
            }
        });

        this.setState({ updatedCompanyData });
    };

    render() {
        const { updatedCompanyData } = this.state;

        return (
            <div className="search-container">
                <div>
                    <Search
                        onChange={(updatedCompanyData) => this.setState({ updatedCompanyData })}
                        data={updatedCompanyData}
                    />
                </div>

                <div className="container-search">
                    <div className="row">
                        <div className="col-md-3">
                            <CompanyFilter
                                data={updatedCompanyData}
                                handleFilterChange={this.handleFilterChange}
                                handleSliderChange={this.handleSliderChange}
                                resetData={this.resetData}
                                handleSort={this.handleSort}
                                checkBoxList={configData.checkBoxList}
                                sortList={configData.sortList}
                            />
                        </div>
                        <div className="col-md-9">
                            <CompanyList companyDataList={updatedCompanyData} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

import jwtDecode from 'jwt-decode';
import jwt from 'jsonwebtoken';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }
    const decoded = jwtDecode(user.token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        console.warn('access token expired');
        return false;
    } else if (!user.isVerified) {
        return false;
    } else {
        return true;
    }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const user = localStorage.getItem('user');
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

/**
 * Sets the session
 * @param {*} user
 */
const setSession = (user) => {
    if (user) {
        const token = jwt.sign(user, 'axisMa', {
            issuer: 'axisMa'
        });

        const userObj = {
            token: token,
            email: user.email,
            userName: user.userName,
            lastName: user.lastName,
            isVerified: user.isVerified
        };
        localStorage.setItem('user', JSON.stringify(userObj));
    } else {
        localStorage.removeItem('user');
    }
};

export { isUserAuthenticated, getLoggedInUser, setSession };

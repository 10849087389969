import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';

import { brodcastMail } from '../../redux/actions';

import Button from '../../components/button';
import InputField from '../../components/inputField';
import { ContactUsToAdmin, ContactUsToRecipient } from '../../lib/emailTemplate';

import '../../assets/styles/css/pages/home/bookAppoinment.css';
import PlayBook from '../../assets/images/PLAYBOOK.pdf';


const ContactUs = (props) => {
    const [form, setForm] = React.useState({ firstName: '', lastName: '', companyName: '', phoneNumber: 0, emailAddress: '', reasonForInterest: '' });
    const [errorMessage, setErrorMessage] = React.useState({
        firstName: false,
        lastName: false,
        companyName: false,
        phoneNumber: false,
        emailAddress: false,
        reasonForInterest: false,
    });
    const [isEquirySubmitted, setEnquirySubmit] = React.useState(false);

    const handleOnChange = (event, field) => {
        const updatedForm = JSON.parse(JSON.stringify(form));
        const errorMessageForm = JSON.parse(JSON.stringify(errorMessage));
        updatedForm[field] = event.target.value;
        errorMessageForm[field] = false;

        setForm(updatedForm);
        setErrorMessage(errorMessageForm);
    };
    
    const handleOnClick = () => {
        const { firstName, lastName, companyName, phoneNumber, emailAddress, reasonForInterest } = form;

        if (phoneNumber && emailAddress && reasonForInterest && firstName && lastName && companyName) {
            const adminMailDetails = ContactUsToAdmin(form);
            const recipentMailDetails = ContactUsToRecipient(form);
            props.brodcastMail(
                adminMailDetails.recipient,
                adminMailDetails.subject,
                adminMailDetails.body
            );
            props.brodcastMail(
                recipentMailDetails.recipient,
                recipentMailDetails.subject,
                recipentMailDetails.body
            );
            setEnquirySubmit(true);
            setForm({ firstName: '', lastName: '', companyName: '', phoneNumber: 0, emailAddress: '', reasonForInterest: '' });
            setErrorMessage({
                firstName: false,
                lastName: false,
                companyName: false,
                phoneNumber: false,
                emailAddress: false,
                reasonForInterest: false,
            });
            var siteurl = document.getElementById("siteUrl").value;
            var a = document.createElement('A');
            a.href = siteurl;
            a.download = siteurl.substr(siteurl.lastIndexOf('/') + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setTimeout(() => setEnquirySubmit(false), 3000);
           
        } else {
            const errMsg = JSON.parse(JSON.stringify(errorMessage));
            if (!firstName) {
                errMsg.firstName = true;
                setErrorMessage(errMsg);
            }
          
            if (!lastName) {
                errMsg.lastName = true;
                setErrorMessage(errMsg);
            }
            if (!companyName) {
                errMsg.companyName = true;
                setErrorMessage(errMsg);
            }
            if (!emailAddress) {
                errMsg.emailAddress = true;
                setErrorMessage(errMsg);
            }
            if (!phoneNumber) {
                errMsg.phoneNumber = true;
                setErrorMessage(errMsg);
            }
            if (!reasonForInterest) {
                errMsg.reasonForInterest = true;
                setErrorMessage(errMsg);
            }
            // var siteurl = document.getElementById("siteUrl").value;
            // window.open(siteurl);

           
        }
    };

    return (
        <>
            <div className="row">
        <div className="col-sm-6">
            <InputField
                inputType="text"
                label="First Name"
                className="my-3"
                required = ""
                value={!isEquirySubmitted ? form.firstName : ''}
                onChange={(event) => handleOnChange(event, 'firstName')}
                helperText={errorMessage.firstName ? 'ⓘ First Name is required' : null}
            />
           
        </div>
        <div className="col-sm-6">
            <InputField
                inputType="text"
                label="Last Name"
                className="my-3"
                required = ""
                value={!isEquirySubmitted ? form.lastName : ''}
                onChange={(event) => handleOnChange(event, 'lastName')}
                helperText={errorMessage.lastName ? 'ⓘ Last Name is required' : null}
            />
        </div>
        <div className="col-sm-6">
            <InputField
                inputType="text"
                label="Company Name"
                className="my-3"
                required = ""
                value={!isEquirySubmitted ? form.companyName : ''}
                onChange={(event) => handleOnChange(event, 'companyName')}
                helperText={errorMessage.companyName ? 'ⓘ Company Name is required' : null}
            />
        </div>
        <div className="col-sm-6">
            <InputField
                inputType="email"
                label="Email Address"
                className="my-3"
                required = ""
                value={!isEquirySubmitted ? form.emailAddress : ''}
                onChange={(event) => handleOnChange(event, 'emailAddress')}
                helperText={errorMessage.emailAddress ? 'ⓘ Email Address is required' : null}
            />
        </div>
        <div className="col-sm-6">
            <InputField
                inputType="number"
                label="Phone Number"
                className="my-3"
                required = ""
                value={!isEquirySubmitted ? form.phoneNumber : ''}
                onChange={(event) => handleOnChange(event, 'phoneNumber')}
                helperText={errorMessage.phoneNumber ? 'ⓘ Phone Number is required' : null}
            />
        </div>
        <div className="col-sm-6">
            <InputField
                inputType="text"
                label="Reason For Interest"
                className="my-3"
                required = ""
                value={!isEquirySubmitted ? form.reasonForInterest : ''}
                onChange={(event) => handleOnChange(event, 'reasonForInterest')}
                helperText={errorMessage.reasonForInterest ? 'ⓘ Reason For Interest is required' : null}
            />
        </div>
        {/* <div className="col-sm-12">
        <InputField
            inputType="checkbox"
            label=""
            className="my-3 contact-us-checkbox"                
            />
         <p className="checkbox-content">By submitting this form, you give Midaxo permission to send you information that may be of interest to you by email. You may unsubscribe from these communications at anytime. For more info on our commitment to protecting your privacy, check out our Privacy Policy.</p>
        </div> */}
         {/* <InputField
                inputType="hidden"
                value={PlayBook}
               id='siteUrl'
            /> */}
            <input type="hidden" id="siteUrl" name="siteUrl"  value={PlayBook}/>
        <div className="col-sm-12">
       
            <Button variant="outlined" onClick={handleOnClick} className="book-button contactUs-btn"> 
            Download Playbook
               {/* <a href={PlayBook} className="text-white book-button contactUs-btn" download> </a>  */}
            </Button> 
        
            {isEquirySubmitted ? (
                <div className="d-flex text-white justify-content-center">
                    Thank you for your Interest. We will contact you ASAP
                </div>
            ) : null}
      
        </div>
    </div>
    </>

                  
    );
};

const mapStateToProps = (state) => {
    const { mail, loading, error } = state.Common;
    return { mail, loading, error };
};

export default React.memo(connect(mapStateToProps, { brodcastMail })(withRouter(ContactUs)));

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { RestCall } from '../../lib/RestCall';
import { brodcastMailFailed, brodcastMailSuccess } from './actions';
import { TRIGGER_MAIL } from './constants';

function* brodcastMail({ payload: { recipient, subject, body } }) {
    try {
        const options = {
            body: { recipient, subject, body },
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };
        const response = yield call(RestCall, '/broadcastMail', options);
        yield put(brodcastMailSuccess(response));
    } catch (error) {
        let message;
        try {
            if (error && error.message) {
                message = error.message;
            } else {
                yield error.json().then((json) => {
                    message = json.message;
                });
            }

            if (!message) {
                switch (error.status) {
                    case 500:
                        message = 'Internal Server Error';
                        break;
                    case 401:
                        message = 'Invalid credentials';
                        break;
                    default:
                        message = error;
                }
            }
        } catch (error) {
            message = 'Internal Server Error';
        } finally {
            yield put(brodcastMailFailed(message));
        }
    }
}

export function* watchBroadcastMail() {
    yield takeEvery(TRIGGER_MAIL, brodcastMail);
}

function* commonSaga() {
    yield all([fork(watchBroadcastMail)]);
}

export default commonSaga;

import React from 'react';
import { Button as MatButton } from '@material-ui/core';

import '../assets/styles/css/components/button.css';

const Button = ({ children, variant, type, onClick, className, style }) => {
    return (
        <div className={`d-flex justify-content-center ${className}`}>
            <MatButton
                variant={variant}
                onClick={onClick}
                type={type}
                className="button-outline"
                style={style}
            >
                {children}
            </MatButton>
        </div>
    );
};

export default Button;

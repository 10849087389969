import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import AOS from 'aos';

import { configureStore } from './redux/store';
import App from './App';

import 'bootstrap/dist/css/bootstrap.css';
import './assets/styles/css/index.css';
import './assets/styles/css/common/colors.css';
// import 'aos/dist/aos.css';

// AOS.init();

ReactDOM.render(
    <Provider store={configureStore()}>
        <App />
    </Provider>,
    document.getElementById('root')
);

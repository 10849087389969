import React from 'react';
import { Checkbox } from '@material-ui/core';

const CheckboxWithLabel = (props) => {
    const { label, onChange, defaultChecked } = props;

    return (
        <div className="custom-control custom-checkbox">
            <Checkbox
                className="terms-and-condition-checkbox"
                color="default"
                defaultChecked={defaultChecked}
                onChange={(event) => onChange(event)}
            />
            <label className="">{label}</label>
        </div>
    );
};

export default CheckboxWithLabel;

// @flow
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILED,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,
    VERIFY_EMAIL,
    VERIFY_EMAIL_SUCCESS,
    VERIFY_EMAIL_FAILED,
    TRIGGER_OTP,
    TRIGGER_OTP_SUCCESS,
    TRIGGER_OTP_FAILED,
    THIRD_PARTY_AUTH,
    THIRD_PARTY_AUTH_SUCCESS,
    THIRD_PARTY_AUTH_FAILED
} from './constants';

import { getLoggedInUser } from '../../lib/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false
};

const Auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true };
        case LOGIN_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null };
        case LOGIN_USER_FAILED:
            return { ...state, error: action.payload, loading: false };
        case REGISTER_USER:
            return { ...state, loading: true };
        case REGISTER_USER_SUCCESS:
            return { ...state, user: action.payload[0], loading: false, error: null };
        case REGISTER_USER_FAILED:
            return { ...state, error: action.payload, loading: false };
        case LOGOUT_USER:
            return { ...state, user: null };
        case FORGET_PASSWORD:
            return { ...state, loading: true };
        case FORGET_PASSWORD_SUCCESS:
            return { ...state, passwordResetStatus: action.payload, loading: false, error: null };
        case FORGET_PASSWORD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case VERIFY_EMAIL:
            return { ...state, loading: true };
        case VERIFY_EMAIL_SUCCESS:
            return { ...state, user: action.payload[0], loading: false, error: null };
        case VERIFY_EMAIL_FAILED:
            return { ...state, error: action.payload, loading: false };
        case TRIGGER_OTP:
            return { ...state, loading: true };
        case TRIGGER_OTP_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null };
        case TRIGGER_OTP_FAILED:
            return { ...state, error: action.payload, loading: false };
        case THIRD_PARTY_AUTH:
            return { ...state, loading: true };
        case THIRD_PARTY_AUTH_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null };
        case THIRD_PARTY_AUTH_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
};

export default Auth;

import React from 'react';
import { LinkedIn as Linkedin } from 'react-linkedin-login-oauth2';

const LinkedIn = ({ onSuccessLinkedInAuth, onFailureLinkedInAuth }) => {
    return (
        <Linkedin
            clientId={process.env.REACT_APP_LINKEDIN_APP_ID}
            onSuccess={onSuccessLinkedInAuth}
            onFailure={onFailureLinkedInAuth}
            // redirectUri="http://localhost:3000/auth/linkedin/callback"
            redirectUri="https://axisma.com/auth/linkedin/callback"
            className="my-2 d-flex justify-content-center align-items-center"
            renderElement={({ onClick, disabled }) => (
                <div
                    id="linkedin-dark"                    
                    className="fab fa-linkedin-in media-icon"
                    onClick={onClick}
                    disabled={disabled}
                />
            )}
        />
    );
};

export default LinkedIn;

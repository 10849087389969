// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-search {
    background-color: #f6f6f6;
    padding: 0 100px;
    height: 100%;
}

.search-container {
    padding-top: 160px;
}

@media (max-width: 1000px) {
    .container-search {
        padding: 0;
    }
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/css/pages/company/index.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".container-search {\n    background-color: #f6f6f6;\n    padding: 0 100px;\n    height: 100%;\n}\n\n.search-container {\n    padding-top: 160px;\n}\n\n@media (max-width: 1000px) {\n    .container-search {\n        padding: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

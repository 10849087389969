import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { Tabs, Tab, Row, Col } from 'react-bootstrap';

import { brodcastMail } from '../../redux/actions';

import Button from '../../components/button';
import InputField from '../../components/inputField';
import { BookAppoinmentToAdmin, BookAppoinmentToRecipient } from '../../lib/emailTemplate';

import '../../assets/styles/css/pages/home/bookAppoinment.css';

const BookAppoinment = (props) => {
    const [form, setForm] = React.useState({ name: '', contactNumber: 0, email: '', enquiry: '' });
    const [errorMessage, setErrorMessage] = React.useState({
        name: false,
        contactNumber: false,
        email: false,
        enquiry: false
    });
    const [isEquirySubmitted, setEnquirySubmit] = React.useState(false);

    const handleOnChange = (event, field) => {
        const updatedForm = JSON.parse(JSON.stringify(form));
        const errorMessageForm = JSON.parse(JSON.stringify(errorMessage));
        updatedForm[field] = event.target.value;
        errorMessageForm[field] = false;

        setForm(updatedForm);
        setErrorMessage(errorMessageForm);
    };

    const handleOnClick = () => {
        const { name, email, contactNumber, enquiry } = form;

        if (contactNumber && email && enquiry && name) {
            const adminMailDetails = BookAppoinmentToAdmin(form);
            const recipentMailDetails = BookAppoinmentToRecipient(form);
            props.brodcastMail(
                adminMailDetails.recipient,
                adminMailDetails.subject,
                adminMailDetails.body
            );
            props.brodcastMail(
                recipentMailDetails.recipient,
                recipentMailDetails.subject,
                recipentMailDetails.body
            );
            setEnquirySubmit(true);
            setForm({ name: '', contactNumber: '', email: '', enquiry: '' });
            setErrorMessage({
                name: false,
                contactNumber: false,
                email: false,
                enquiry: false
            });
            setTimeout(() => setEnquirySubmit(false), 3000);
        } else {
            const errMsg = JSON.parse(JSON.stringify(errorMessage));
            if (!name) {
                errMsg.name = true;
                setErrorMessage(errMsg);
            }
            if (!email) {
                errMsg.email = true;
                setErrorMessage(errMsg);
            }
            if (!contactNumber) {
                errMsg.contactNumber = true;
                setErrorMessage(errMsg);
            }
            if (!enquiry) {
                errMsg.enquiry = true;
                setErrorMessage(errMsg);
            }
        }
    };

    return (
        <>
            <div className="container">
                <div id="bookAppointment" className={`appoinment-container ${props.className}`}>
                    <div className="appoinment-header">Contact Us for a Free Valuation</div>

                    <Row>
                        <Col>
                            <Tabs defaultActiveKey="buyers" id="controlled-tab-example">
                                <Tab eventKey="buyers" title="Buyers">
                                    <form className="appoinment-form" autoComplete="off">
                                        <div className="input-field-wrapper">
                                            <Row>
                                                <Col lg="4">
                                                    <InputField
                                                        inputType="text"
                                                        label="Name"
                                                        className="my-3"
                                                        value={!isEquirySubmitted ? form.name : ''}
                                                        onChange={(event) =>
                                                            handleOnChange(event, 'name')
                                                        }
                                                        helperText={
                                                            errorMessage.name
                                                                ? 'ⓘ Name is required'
                                                                : null
                                                        }
                                                    />
                                                </Col>
                                                <Col lg="4">
                                                    <InputField
                                                        inputType="number"
                                                        label="Contact Number"
                                                        className="my-3"
                                                        value={
                                                            !isEquirySubmitted
                                                                ? form.contactNumber
                                                                : ''
                                                        }
                                                        onChange={(event) =>
                                                            handleOnChange(event, 'contactNumber')
                                                        }
                                                        helperText={
                                                            errorMessage.contactNumber
                                                                ? 'ⓘ Contact Number is required'
                                                                : null
                                                        }
                                                    />
                                                </Col>
                                                <Col lg="4">
                                                    <InputField
                                                        inputType="email"
                                                        label="Email"
                                                        className="my-3"
                                                        value={!isEquirySubmitted ? form.email : ''}
                                                        onChange={(event) =>
                                                            handleOnChange(event, 'email')
                                                        }
                                                        helperText={
                                                            errorMessage.email
                                                                ? 'ⓘ Email is required'
                                                                : null
                                                        }
                                                    />
                                                </Col>
                                                <Col lg="12">
                                                    <Button
                                                        variant="outlined"
                                                        onClick={handleOnClick}
                                                        className="book-button"
                                                    >
                                                        Book Your Appoinment
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </form>
                                </Tab>
                                <Tab eventKey="seller" title="Sellers">
                                    <form className="appoinment-form" autoComplete="off">
                                        <div className="input-field-wrapper">
                                            <Row>
                                                <Col lg="4">
                                                    <InputField
                                                        inputType="text"
                                                        label="Name"
                                                        className="my-3"
                                                        value={!isEquirySubmitted ? form.name : ''}
                                                        onChange={(event) =>
                                                            handleOnChange(event, 'name')
                                                        }
                                                        helperText={
                                                            errorMessage.name
                                                                ? 'ⓘ Name is required'
                                                                : null
                                                        }
                                                    />
                                                </Col>
                                                <Col lg="4">
                                                    <InputField
                                                        inputType="number"
                                                        label="Contact Number"
                                                        className="my-3"
                                                        value={
                                                            !isEquirySubmitted
                                                                ? form.contactNumber
                                                                : ''
                                                        }
                                                        onChange={(event) =>
                                                            handleOnChange(event, 'contactNumber')
                                                        }
                                                        helperText={
                                                            errorMessage.contactNumber
                                                                ? 'ⓘ Contact Number is required'
                                                                : null
                                                        }
                                                    />
                                                </Col>
                                                <Col lg="4">
                                                    <InputField
                                                        inputType="email"
                                                        label="Email"
                                                        className="my-3"
                                                        value={!isEquirySubmitted ? form.email : ''}
                                                        onChange={(event) =>
                                                            handleOnChange(event, 'email')
                                                        }
                                                        helperText={
                                                            errorMessage.email
                                                                ? 'ⓘ Email is required'
                                                                : null
                                                        }
                                                    />
                                                </Col>
                                                <Col lg="12">
                                                    <Button
                                                        variant="outlined"
                                                        onClick={handleOnClick}
                                                        className="book-button"
                                                    >
                                                        Book Your Appoinment
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </form>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>

                    {isEquirySubmitted ? (
                        <div className="d-flex text-white justify-content-center">
                            Thank you for your Enquiry. We will contact you ASAP
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    const { mail, loading, error } = state.Common;
    return { mail, loading, error };
};

export default React.memo(connect(mapStateToProps, { brodcastMail })(withRouter(BookAppoinment)));

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 575.98px) {
    .contact-us-checkbox{
        width: 100% !important;
        height: 150px !important;
    }
    /* .contactUs-btn{
        margin-top: 50px !important;
    } */
}


@media (max-width: 767.98px) {
    /* .contactUs-btn{
        margin-top: 55px !important;
    } */
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/css/pages/resources/resources2.css"],"names":[],"mappings":"AAAA;IACI;QACI,sBAAsB;QACtB,wBAAwB;IAC5B;IACA;;OAEG;AACP;;;AAGA;IACI;;OAEG;AACP","sourcesContent":["@media (max-width: 575.98px) {\n    .contact-us-checkbox{\n        width: 100% !important;\n        height: 150px !important;\n    }\n    /* .contactUs-btn{\n        margin-top: 50px !important;\n    } */\n}\n\n\n@media (max-width: 767.98px) {\n    /* .contactUs-btn{\n        margin-top: 55px !important;\n    } */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';

import LazyImage from '../../components/lazyImage';

import buySideRep from '../../assets/images/buy-side.jpg';
import sellSideRep from '../../assets/images/sell-side.jpg';
import marketplace from '../../assets/images/marketplace.jpg';
import buySideRepPoster from '../../assets/images/poster/buy-side.jpg';
import sellSideRepPoster from '../../assets/images/poster/sell-side.jpg';
import marketplacePoster from '../../assets/images/poster/marketplace.jpg';

import '../../assets/styles/css/pages/home/services.css';
import '../../assets/plugins/fontawesome/css/all.min.css';
const Services = () => {
    return (
        <div className="service-section">
            <div className="container">
                <div className="row no-gutters">
                    <div className="col-lg-8">
                        <div className="card">
                            <div className="card-body">
                                <LazyImage
                                    className="service-image home-service-img img-fluid mb-4"
                                    src={buySideRep}
                                    placeholderImg={buySideRepPoster}
                                    alt="m&a deals"
                                    title="m&a deals"
                                />
                                <h2 className="card-title">Buy-Side Representation</h2>
                                <p className="card-text ">
                                    The team of experts at Axis M&A focuses on M&A in the the
                                    Technology and Staffing Sectors. Because of the ever-growing
                                    portfolio at Axis M&A, we have great relationships with those
                                    interested in merging with or acquiring a tech or staffing
                                    company. The experts at Axis M&A are so familiar with these
                                    industries that we are positioned to help interested parties
                                    acquire a firm in an industry-leading time frame.
                                </p>
                                <a className="float-right" href="/services#buySide">
                                    <i className="fas fa-plus mr-1"></i>More
                                </a>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <LazyImage
                                    className="service-image home-service-img img-fluid mb-4"
                                    src={sellSideRep}
                                    placeholderImg={sellSideRepPoster}
                                    alt="current m&a deals"
                                    title="current m&a deals"
                                />
                                <h2 className="card-title">Sell-Side Representation</h2>
                                <p className="card-text sell-sideibold">
                                    <b>
                                        Axis M&A is the only M&A firm that guarantees the sale of
                                        your company
                                    </b>
                                </p>
                                <p className="card-text">
                                    The team of experts at Axis M&A focuses on M&A in the the
                                    Technology and Staffing Sectors. Because of the ever-growing
                                    portfolio at Axis M&A, we have great relationships with those
                                    interested in merging with or acquiring a tech or staffing
                                    company. The experts at Axis M&A are so familiar with these
                                    industries that we are positioned to help interested parties
                                    acquire a firm in an industry-leading time frame.
                                </p>

                                <a className="float-right" href="/services#sellSide">
                                    <i className="fas fa-plus mr-1"></i>More
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <LazyImage
                                    className="service-image market-image img-fluid mb-4"
                                    src={marketplace}
                                    placeholderImg={marketplacePoster}
                                    alt="m&a company"
                                    title="m&a company"
                                />
                                <h2 className="card-title">Marketplace</h2>
                                {/* <p className="card-text">Axis M&A is proud to be an industry leader in M&A innovation. The launch of Axis M&A Marketplace will mark a disruption in traditional M&A consulting. This unique tool will foster a transparent approach to buying or selling a company. Axis M&A Marketplace will allow for company stakeholders, interested in selling, to create a company profile and list themselves on the marketplace for potential buyers to browse. Buyers are now able to browse a database of new company listings and be notified when a company fitting their specific wants hits the market. The Axis M&A Marketplace will protect company identity all while providing more transparency to the M&A world than ever before.
                                </p> */}
                                <p className="card-text">
                                    Axis M&A Marketplace brings a new standard of transparency to
                                    the M&A industry. Browse, research, save, and contact listed
                                    companies all from the Axis M&A Marketplace dashboard.{' '}
                                </p>
                                <a className="signup-underline" href="/auth/register/buyer">
                                    Sign Up
                                </a>
                                <a className="float-right" href="/services#marketplace">
                                    <i className="fas fa-plus mr-1"></i>More
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;

import React, { Component } from 'react';
import Slider from 'react-slick';
import image1 from '../../assets/images/user-img.jpg';
import '../../assets/styles/css/pages/home/testimonials.css';

export default class Responsive extends Component {
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 0,
            autoplay: true,
            autoplaySpeed: 2000,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 765,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className="container">
                <Slider {...settings}>
                    <div>
                        <div className="testimonial-content">
                            <div className="testimonial-content-left">
                                <img
                                    src={image1}
                                    alt={`business-profile`}
                                    className="testimonial-image-container img-fluid"
                                />
                                <h3>Ganesh</h3>
                                <h4>President , Banglore</h4>
                            </div>
                            <div className="testimonial-text-container">
                                <span className="testimonial-text">
                                    <i className="fas fa-quote-left mr-2"></i>Through a well-planned
                                    strategy, Axis M&A helped me sell my 2nd generation staffing
                                    firm to a top regional company. Thank you for your dedication!
                                    <i className="fas fa-quote-right ml-2"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="testimonial-content">
                            <div className="testimonial-content-left">
                                <img
                                    src={image1}
                                    alt={`business-profile`}
                                    className="testimonial-image-container img-fluid"
                                />
                                <h3>Michael</h3>
                                <h4>Founder & CEO , Tampa FL</h4>
                            </div>
                            <div className="testimonial-text-container">
                                <span className="testimonial-text">
                                    <i className="fas fa-quote-left mr-2"></i>Thank you to Axis M&A
                                    for helping sell our business. Things went quickly and smoothly,
                                    and we could not have asked for better service.
                                    <i className="fas fa-quote-right ml-2"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="testimonial-content">
                            <div className="testimonial-content-left">
                                <img
                                    src={image1}
                                    alt={`business-profile`}
                                    className="testimonial-image-container img-fluid"
                                />
                                <h3>Malik</h3>
                                <h4>CEO , Chicago</h4>
                            </div>
                            <div className="testimonial-text-container">
                                <span className="testimonial-text">
                                    <i className="fas fa-quote-left mr-2"></i>We cannot express how
                                    much Axis M&A s team helped take us through the often stressful
                                    journey of selling and closing our business of 14 years. From
                                    listing advice to discreetly contacting and screening potential
                                    buyers, they were professional and kind; helping us to stay on
                                    course and move quickly.
                                    <i className="fas fa-quote-right ml-2"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="testimonial-content">
                            <div className="testimonial-content-left">
                                <img
                                    src={image1}
                                    alt={`business-profile`}
                                    className="testimonial-image-container img-fluid"
                                />
                                <h3>Umesh</h3>
                                <h4>Founder</h4>
                            </div>
                            <div className="testimonial-text-container">
                                <span className="testimonial-text">
                                    <i className="fas fa-quote-left mr-2"></i>Axis M&A did an
                                    excellent job in selling my business. They not only made it easy
                                    for me, but they also made it easy for the buyer, which is the
                                    hardest part of the transaction. Axis M&A provided enough
                                    information and assistance so that the transition was perfectly
                                    smooth, and I can honestly say that all of my previous employees
                                    are truly happy. For anyone looking to buy or sell a business, I
                                    would definitely recommend hiring Axis.
                                    <i className="fas fa-quote-right ml-2"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="testimonial-content">
                            <div className="testimonial-content-left">
                                <img
                                    src={image1}
                                    alt={`business-profile`}
                                    className="testimonial-image-container img-fluid"
                                />
                                <h3>Erica</h3>
                                <h4>Founder & CEO , Georgia</h4>
                            </div>
                            <div className="testimonial-text-container">
                                <span className="testimonial-text">
                                    <i className="fas fa-quote-left mr-2"></i>Axis M&A successfully
                                    facilitated the entire process of preparing and selling my
                                    business, allowing me to gain maximum value and transition the
                                    operation smoothly to a large international firm.{' '}
                                    <i className="fas fa-quote-right ml-2"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        );
    }
}

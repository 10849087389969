// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-follow-us {
    color: #fff;
    background:#808080;
    padding: 20px;
    text-align: center;
    /* padding-right: 426px; */
}

.footer-followus-container {
    background: #eae8e8;
    padding: 7px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/css/components/footer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,0BAA0B;AAC9B;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".footer-follow-us {\n    color: #fff;\n    background:#808080;\n    padding: 20px;\n    text-align: center;\n    /* padding-right: 426px; */\n}\n\n.footer-followus-container {\n    background: #eae8e8;\n    padding: 7px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';

import LazyImage from '../../components/lazyImage';

import ourProcessBuy from '../../assets/images/our-process/img-1.jpg';
import ourProcessPosterBuy from '../../assets/images/our-process/img-1.jpg';
import ourProcessSell from '../../assets/images/our-process/img-2.jpg';
import ourProcessPosterSell from '../../assets/images/our-process/img-2.jpg';

import '../../assets/styles/css/pages/services/services.css';

const OurProcess = () => {
    return (
        <div className="container" id="ourProcess">
            <div className="row">
                <div className="col-md-6 col-lg-6 d-flex">
                    <div className="card bg-grey flex-fill">
                        <div className="card-header">
                            <h2 className="buy_side_header">Buy Side </h2>
                        </div>
                        <LazyImage
                            className="sell_side_representation_image img-fluid"
                            src={ourProcessBuy}
                            placeholderImg={ourProcessPosterBuy}
                            alt="m and a consulting"
                            title="m and a consulting"
                        />
                        <div className="card-body">
                            <p className="buy_side_representation_content">
                                All businesses strive to grow, expand, and gain a competitive
                                advantage. While success can happen organically,{' '}
                                <a className="text-dark" href="/">
                                    Mergers and Acquisitions (M&A)
                                </a>{' '}
                                drive the greatest growth among technology and staffing companies.
                                <br />
                                <br />
                                <span className="first_letter_text">A</span>xis M&A leverages a
                                truly unique buying process, from representing a company seeking to
                                expand via acquisition, to assisting a management team looking to
                                execute a buyout. Axis M&A has proven expertise in effectively
                                assisting clients to execute their M&A initiatives.
                                <br />
                                <br />
                                Axis M&A provides world-class consulting for identifying acquisition
                                targets, conducting preliminary due diligence, establishing pricing
                                criteria, and arranging acquisition financing for buyers. Industry
                                expertise and innovative processes ensure seamless communication and
                                an industry-leading pace from the deal kick-off meeting to the
                                transaction close.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-6 d-flex">
                    <div className="card bg-grey flex-fill">
                        <div className="card-header">
                            <h2 className="buy_side_header">Sell Side </h2>
                        </div>
                        <LazyImage
                            className="sell_side_representation_image img-fluid"
                            src={ourProcessSell}
                            placeholderImg={ourProcessPosterSell}
                            alt="m & a advisors"
                            title="m & a advisors"
                        />
                        <div className="card-body">
                            <p className="buy_side_representation_content">
                                <span className="first_letter_text">A</span>xis M&A has a proven
                                track record of maximizing value for clients in the marketplace when
                                acting as a sell-side advisor on their behalf. Our unique processes
                                allow buyers and sellers to closely manage, and quickly move through
                                the key steps of the sell-side process. This approach enables us to
                                streamline the transaction process, gain access to critical
                                counter-parties, and ultimately, maximize the value for each
                                client’s transaction. In addition,{' '}
                                <a className="text-dark" href="/">
                                    Axis M&A
                                </a>{' '}
                                leverages its exclusive network of buyers, with billions of dollars
                                in cash set aside for acquisitions, to match sellers with buyers
                                that are actively searching in the market.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurProcess;

import React from 'react';

import LazyImage from '../../components/lazyImage';

import businessValuations from '../../assets/images/our-process/img-3.jpg';
import businessValuationsPoster from '../../assets/images/our-process/img-3.jpg';
import '../../assets/styles/css/pages/services/services.css';

const Valuations = () => {
    return (
        <div className="container services-container" id="valuation">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card bg-grey mb-0">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <LazyImage
                                        className="sell_side_representation_image img-fluid"
                                        src={businessValuations}
                                        placeholderImg={businessValuationsPoster}
                                        alt="marketplace_image"
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <h2 className="buy_side_header mb-4">Valuations </h2>
                                    <p className="buy_side_representation_content">
                                        <span className="first_letter_text">I</span>f you are an
                                        owner of a tech in a tech or staffing company, and you are
                                        wondering what your company is worth, please contact the
                                        team at Axis M&A for a consultation and valuation. Our
                                        in-house valuation will ensure the proper valuation is
                                        assigned to your company and the value is accurately
                                        marketed to our exclusive list of buyers and sellers.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Valuations;

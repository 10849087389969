import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../../components/footer';
import {Tabs, Tab} from 'react-bootstrap-tabs';
import ResourceSlider from './ResourcesSlider.js';
import PlayBookSlider from "./PlayBook";
// import ContactUsForm from './ContactUsForm';
import ContactUs from './ContactUsForm';
import '../../assets/styles/css/pages/resources/resources.css';
import '../../assets/styles/css/pages/resources/resources2.css'

export default class Resources extends Component {
    render() {
        return (
            <>
            <Helmet>
                <link rel="canonical" href="https://www.axisma.com/resources/"/>
            </Helmet>
           
             <div className="resources-banner">
                 {/* Slider Section */}
                <div className="container-fluid">
                     <ResourceSlider /> 
                </div>
                 {/* Slider Section */}

                 {/* Tabs Section */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card mb-2 resource-library">
                                <div className="card-body p-0">
                                    <h3 className="card-title">Axis M&amp;A Resources Library</h3>
                                    <p>Access the resources you need to successfully build, improve and execute your M&amp;A process.</p>
                               
                                    <Tabs  selected={3}>
                                        {/* <Tab label="Video’s" disabled>
                                        Coming Soon
                                        </Tab>
                                        <Tab label="Guides" disabled>
                                        Coming Soon
                                        </Tab>
                                        <Tab label="Podcast Episodes" disabled>
                                        Coming Soon
                                        </Tab> */}
                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" label="Playbook Samples">
                                             <PlayBookSlider /> 
                                        </Tab>
                                        {/* <Tab label="Whitepapers" disabled>
                                        Coming Soon
                                        </Tab>
                                        <Tab label="Blog" disabled>
                                        Coming Soon
                                        </Tab> */}
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Tabs Section */}

                {/* Contact us Section */}
                <div className="container-fluid">
                    <div className="row resource-form" id="contact_us_form">
                        <div className="col-lg-4">
                            <div className="card resource-form-content mt-lg-5 mt-3">
                                <div className="card-body">
                                    <h3 className="card-title">Have a Question?</h3>
                                    <p>We’d love to hear from you! Inquire about any of our listed services, or how Axis M&A can help you in your next merger or acquisition.
                                    <br/><br/>Please complete the form and we will get in touch with you shortly to schedule a fully confidential conversation.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="card resource-form-input mt-lg-5 mt-3">
                                <div className="card-body p-0">
                                    <h3 className="card-title mt-4">Download Playbook</h3>
                                    {/* <ContactUsForm /> */}
                                    <ContactUs />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
                {/* Contact us Section */}

            </div>
             <Footer />
            </>
        );
    }
}

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Alert } from '@material-ui/lab';

import { loginUser, thirdPartyAuth } from '../../redux/actions';
import { isUserAuthenticated } from '../../lib/authUtils';
import InputField from '../../components/inputField';
import LinkedIn from '../../components/linkedInBtn';
// import FacebookBtn from '../../components/facebookBtn';
import GoogleBtn from '../../components/googleBtn';
import utils from '../../lib/utils';
import Layout from './layout';
import Spinner from '../../components/spinner';

import '../../assets/styles/css/pages/auth/index.css';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                email: null,
                password: null
            },
            errorMessage: {
                email: false,
                password: false
            }
        };

        this.role = utils.getRoleFromUrl(this.props);
        this.isBuyer = utils.isBuyer(this.role);
    }

    /**
     * Handles the submit
     */
    handleValidSubmit = (event) => {
        event.preventDefault();
        const { errorMessage, form } = this.state;
        const { email, password } = form;

        if (email && password) {
            this.props.loginUser(email, password, this.isBuyer ? 'isBuyer' : 'isSeller');
        } else {
            const errMsg = JSON.parse(JSON.stringify(errorMessage));
            if (!email) {
                errMsg.email = true;
                this.setState({ errorMessage: errMsg });
            }
            if (!password) {
                errMsg.password = true;
                this.setState({ errorMessage: errMsg });
            }
        }
    };

    /**
     * Redirect to root
     */
    renderRedirectToRoot = () => {
        const isAuthTokenValid = isUserAuthenticated();
        if (
            !this.props.loading &&
            isAuthTokenValid &&
            this.props.user &&
            this.props.user.isVerified
        ) {
            return <Redirect to="/" />;
        }
    };

    renderRedirectToConfirm = () => {
        if (!this.props.loading && this.props.user && !this.props.user.isVerified) {
            return <Redirect to={`/auth/confirm/${this.role}`} />;
        }
    };

    handleOnChange = (event, formStateField) => {
        const { form, errorMessage } = this.state;
        form[formStateField] = event.target.value;
        errorMessage[formStateField] = false;

        this.setState({ form, errorMessage });
    };

    onSuccessGoogleAuth = (response) => {
        this.props.thirdPartyAuth(response, 'google', this.isBuyer ? 'isBuyer' : 'isSeller');
    };

    // fbCallback = (response) => {
    //     if (response.email) {
    //         this.props.thirdPartyAuth(response, 'facebook', this.isBuyer ? 'isBuyer' : 'isSeller');
    //     }
    // };

    onSuccessLinkedInAuth = (response) => {
        console.log(response);
        // this.props.thirdPartyAuth(response, 'linkedIn', this.isBuyer ? 'isBuyer' : 'isSeller');
    };

    onFailureAuth = (response) => {
        console.log(response);
    };

    render() {
        const isAuthTokenValid = isUserAuthenticated();
        const { form, errorMessage } = this.state;

        return (
            <>
                {this.props.loading ? <Spinner /> : null}
                {this.renderRedirectToConfirm()}
                {this.renderRedirectToRoot()}
                {!isAuthTokenValid ? (
                    <>
                     <Helmet>
                     <link rel="canonical" href="https://www.axisma.com/auth/login/buyer/"/>
                    </Helmet>
                        <Layout>
                            <div className="header-text">Sign In</div>
                            <form
                                className="login-form"
                                autoComplete="off"
                                onSubmit={(event) => this.handleValidSubmit(event)}
                            >
                                <div className="input-field-container">
                                    <InputField
                                        inputType="email"
                                        type="authForm"
                                        label="Email"
                                        className="w-100 form-field"
                                        value={form.email}
                                        onChange={(event) => this.handleOnChange(event, 'email')}
                                        helperText={
                                            errorMessage.email || form.email === ''
                                                ? 'ⓘ Email is required'
                                                : null
                                        }
                                    />
                                </div>

                                <div className="input-field-container">
                                    <InputField
                                        inputType="password"
                                        type="authForm"
                                        label="Password"
                                        className="w-100 form-field"
                                        value={form.password}
                                        onChange={(event) => this.handleOnChange(event, 'password')}
                                        helperText={
                                            errorMessage.password || form.password === ''
                                                ? 'ⓘ Password is required'
                                                : null
                                        }
                                    />
                                </div>

                                {/* <div className="terms-and-condition-container">
                                    <Checkbox
                                        className="terms-and-condition-checkbox"
                                        color="default"
                                        value={form.termsAndConditions}
                                        // onChange={(event) =>
                                        //     this.handleCheckBoxChange(event, 'termsAndConditions')
                                        // }
                                    />
                                    <span className="terms-and-condition-text">Remember Me</span>
                                </div> */}
                                {this.props.error ? (
                                    <Alert severity="error">{this.props.error}</Alert>
                                ) : null}

                                <div className="signup-button-container">
                                    <button type="submit" className="signup-button">
                                        SIGN IN
                                    </button>
                                </div>

                                <div className="already-have-account-text">
                                    <Link
                                        to={`/auth/forgetPassword/${this.role}`}
                                        className="already-have-account-text"
                                    >
                                        Forgot Password?
                                    </Link>
                                </div>
                            </form>

                            <div className="already-have-account-text">
                                Don't have an account?{' '}
                                <Link
                                    to={`/auth/register/${this.role}`}
                                    className="already-have-account-text"
                                >
                                    Sign Up
                                </Link>
                            </div>

                            <div className="signup-using-container">
                                <div className="signup-using">Or Sign In Using</div>
                                <div className="media-icon-container">
                                    <LinkedIn
                                        onSuccessLinkedInAuth={this.onSuccessLinkedInAuth}
                                        onFailureLinkedInAuth={this.onFailureAuth}
                                    />
                                    {/* <FacebookBtn fbCallback={this.fbCallback} /> */}
                                    <GoogleBtn
                                        onSuccessGoogleAuth={this.onSuccessGoogleAuth}
                                        onFailureGoogleAuth={this.onFailureAuth}
                                    />
                                </div>
                            </div>
                        </Layout>
                    </>
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { user, loading, error } = state.Auth;
    return { user, loading, error };
};

export default connect(mapStateToProps, { loginUser, thirdPartyAuth })(withRouter(Login));

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import View from './view';

import Background from '../../assets/images/about-1.jpg';
import companyData from '../../assets/data/companyList.json';
//  import Similar from "./similar"

class Company extends Component {
    constructor(props) {
        super(props);

        this.state = {
            companyInformation: {}
        };
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        const result = companyData.find(({ sellerID }) => sellerID === id);
        this.setState({ companyInformation: result });
    }

    render() {
        const { companyInformation } = this.state;

        if (!companyInformation) {
            return null;
        }

        return (
            <div>
                <div className="col">
                    <div className="background_area">
                        <img className="background_area" src={Background} alt="buyer-profile" />
                    </div>
                </div>
                <div className="col">
                    <View companyInformation={companyInformation} />
                </div>
                <div className="col">{/* <Similar /> */}</div>
            </div>
        );
    }
}

export default withRouter(Company);

import React from 'react';
import buyside from '../../assets/images/buy-side.jpg';
import '../../assets/styles/css/pages/company/companyView.css';
// import companyData from '../../assets/data/companyList.json';

const View = (props) => {
    const {
        sellingPrice,
        netWorth,
        revenue,
        establishedYear,
        totalEmployees,
        companyName,
        city,
        country
    } = props;
    return (
        <div>
            <div>
                <div className="col-4">
                    <div className="row">
                        <img className="company_image" src={buyside} alt="company_image" />
                    </div>
                    <div className="col-4">
                        <h4 className="selling_details_title">Selling Details</h4>
                        <p className="selling_price_text">Selling Price </p>
                        <span className="selling_price_number"> {sellingPrice}</span>
                        <p className="selling_price_text">Net Worth </p>
                        <span className="selling_price_number">{netWorth}</span>
                        <p className="selling_price_text">Revenue </p>
                        <span className="selling_price_number">{revenue}</span>
                        <h4 className="row business_info_title">Business Information</h4>
                        <p className="year_established_text">Year Established</p>{' '}
                        <span className="year_established_number"> {establishedYear}</span>
                        <p className="year_established_text">Employees</p>
                        <span className="year_established_number"> {totalEmployees}</span>
                        <p className="year_established_text">Documents</p>
                    </div>
                </div>
                <div>
                    <div className="col-8">
                        <h1 className="seller_header">{companyName}</h1>
                        <span className="seller_location">
                            {city}, {country}
                        </span>
                        <button className="get_info_button">Contact</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default View;

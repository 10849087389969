import React, { Component } from 'react';
import BookAppoinment from '../home/bookAppoinment';
import LazyImage from '../../components/lazyImage';
import Footer from '../../pages/home/footer';
import '../../assets/styles/css/pages/about/about.css';
import Aboutus from '../../assets/images/Aboutus-side.jpg';
import AboutusSlider from '../../components/About-us-slider';
import { Helmet } from 'react-helmet';
import AboutusData from '../../pages/data/about-data.json';

export default class About extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>
                        AXIS Mergers & Acquisitions Consulting | IT Services Mergers and
                        Acquisitions
                    </title>
                    <meta
                        name="description"
                        content="Axis M&A is a distinguished technology and staffing M&A Consulting Firm, best known for representing various buyers and sellers in these niche sectors"
                    />
                    <meta
                        name="keywords"
                        content="M&A Consulting Firm, Mergers and Acquisitions Consultants, M&A works, M&A Brokers"
                    />
                    <link rel="canonical" href="https://www.axisma.com/about-us/" />
                </Helmet>
                <div className="ourProcess">
                    <div className="container-fluid">
                        <AboutusSlider />
                    </div>
                </div>
                {AboutusData.map(aboutContent => {
                    return (
                        <div className="service-section">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="card">
                                            <div className="card-body">
                                                <h1 className="card-title">{aboutContent.title}</h1>
                                                <p className="card-text ">
                                                    {aboutContent.content1}
                                                    <br />
                                                    <br />
                                                    {aboutContent.content2}
                                                    <br />
                                                    <br />
                                                    {aboutContent.content3}
                                                    <br />
                                                    <br />
                                                    {aboutContent.content4}
                                                    <br />
                                                    <br />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="card ">
                                            <div className="card-body">
                                                <LazyImage
                                                    className="service-image market-image right-img"
                                                    src={Aboutus}
                                                    // placeholderImg={Aboutus-poster}
                                                    alt="merger & acquisition services"
                                                    title="merger & acquisition services"
                                                />
                                                <p className="card-text mt-3">
                                                    {aboutContent.content5}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <BookAppoinment removeLine className="my-5" />
                            <Footer />
                        </div>
                    );
                })}
            </>
        );
    }
}

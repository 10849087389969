import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { setSession } from '../../lib/authUtils';
import { RestCall } from '../../lib/RestCall';

import {
    LOGIN_USER,
    LOGOUT_USER,
    REGISTER_USER,
    FORGET_PASSWORD,
    VERIFY_EMAIL,
    TRIGGER_OTP,
    THIRD_PARTY_AUTH
} from './constants';

import {
    loginUserSuccess,
    loginUserFailed,
    registerUserSuccess,
    registerUserFailed,
    forgetPasswordSuccess,
    forgetPasswordFailed,
    verifyEmailSuccess,
    verifyEmailFailed,
    triggerOtpSuccess,
    triggerOtpFailed,
    thirdPartyAuthSuccess,
    thirdPartyAuthFailed
} from './actions';

/**
 * Login the user
 * @param {*} payload - username, password & role
 */
function* login({ payload: { email, password, role } }) {
    try {
        const options = {
            body: { email, password, role },
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' }
        };
        const response = yield call(RestCall, '/auth/authenticate', options);
        yield setSession(response);
        yield put(loginUserSuccess(response));
    } catch (error) {
        let message;
        try {
            if (error && error.message) {
                message = error.message;
            } else {
                yield error.json().then((json) => {
                    message = json.message;
                });
            }

            if (!message) {
                switch (error.status) {
                    case 500:
                        message = 'Internal Server Error';
                        break;
                    case 401:
                        message = 'Invalid credentials';
                        break;
                    default:
                        message = error;
                }
            }
        } catch (error) {
            message = 'Internal Server Error';
        } finally {
            yield setSession(null);
            yield put(loginUserFailed(message));
        }
    }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
    try {
        yield setSession(null);
        yield call(() => {
            history.push('/auth/login/buyer');
        });
    } catch (error) {
    } finally {
        window.location.reload();
    }
}

/**
 * Register the user
 * @param {*} payload - username, email, password, lastName & role
 */
function* register({ payload: { firstName, lastName, phone, email, password, role } }) {
    try {
        const options = {
            body: { firstName, lastName, phone, email, password, role },
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };
        const response = yield call(RestCall, '/auth/register', options);
        yield put(registerUserSuccess(response));
    } catch (error) {
        let message;
        try {
            if (error && error.message) {
                message = error.message;
            } else {
                yield error.json().then((json) => {
                    message = json.message;
                });
            }

            if (!message) {
                switch (error.status) {
                    case 500:
                        message = 'Internal Server Error';
                        break;
                    case 401:
                        message = 'Invalid credentials';
                        break;
                    default:
                        message = error;
                }
            }
        } catch (error) {
            message = 'Internal Server Error';
        } finally {
            yield put(registerUserFailed(message));
        }
    }
}

/**
 * forget password
 * @param {*} payload - username
 */
function* forgetPassword({ payload: { username } }) {
    try {
        const options = {
            body: JSON.stringify({ username }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };
        const response = yield call(RestCall, '/auth/forgetPassword', options);
        yield put(forgetPasswordSuccess(response.message));
    } catch (error) {
        let message;
        try {
            if (error && error.message) {
                message = error.message;
            } else {
                yield error.json().then((json) => {
                    message = json.message;
                });
            }

            if (!message) {
                switch (error.status) {
                    case 500:
                        message = 'Internal Server Error';
                        break;
                    case 401:
                        message = 'Invalid credentials';
                        break;
                    default:
                        message = error;
                }
            }
        } catch (error) {
            message = 'Internal Server Error';
        } finally {
            yield put(forgetPasswordFailed(message));
        }
    }
}

/**
 * verify email
 * @param {*} payload - verifyEmailPayload
 */
function* verifyEmail({ payload: verifyEmailPayload }) {
    try {
        const options = {
            body: verifyEmailPayload,
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' }
        };
        const response = yield call(RestCall, '/auth/verifyEmail', options);
        yield setSession(response[0]);
        yield put(verifyEmailSuccess(response));
    } catch (error) {
        let message;
        try {
            if (error && error.message) {
                message = error.message;
            } else {
                yield error.json().then((json) => {
                    message = json.message;
                });
            }

            if (!message) {
                switch (error.status) {
                    case 500:
                        message = 'Internal Server Error';
                        break;
                    case 401:
                        message = 'Invalid credentials';
                        break;
                    default:
                        message = error;
                }
            }
        } catch (error) {
            message = 'Internal Server Error';
        } finally {
            yield setSession(null);
            yield put(verifyEmailFailed(message));
        }
    }
}

/**
 * trigger Otp
 * @param {*} payload - triggerOtpPayload
 */
function* triggerOtp({ payload: triggerOtpPayload }) {
    try {
        const options = {
            body: triggerOtpPayload,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };
        const response = yield call(RestCall, '/auth/triggerOtp', options);
        yield put(triggerOtpSuccess(response.message));
    } catch (error) {
        let message;
        try {
            if (error && error.message) {
                message = error.message;
            } else {
                yield error.json().then((json) => {
                    message = json.message;
                });
            }

            if (!message) {
                switch (error.status) {
                    case 500:
                        message = 'Internal Server Error';
                        break;
                    case 401:
                        message = 'Invalid credentials';
                        break;
                    default:
                        message = error;
                }
            }
        } catch (error) {
            message = 'Internal Server Error';
        } finally {
            yield put(triggerOtpFailed(message));
        }
    }
}

/**
 * thirdPartyAuth
 * @param {*} payload - thirdPartyAuthResponse
 */
function* thirdPartyAuth({ payload: { thirdPartyAuthResponse, service, role } }) {
    try {
        const options = {
            body: { ...thirdPartyAuthResponse, role },
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };
        const response = yield call(RestCall, `/auth/${service}`, options);
        yield setSession(response[0]);
        yield put(thirdPartyAuthSuccess(response[0]));
    } catch (error) {
        let message;
        try {
            if (error && error.message) {
                message = error.message;
            } else {
                yield error.json().then((json) => {
                    message = json.message;
                });
            }

            if (!message) {
                switch (error.status) {
                    case 500:
                        message = 'Internal Server Error';
                        break;
                    case 401:
                        message = 'Invalid credentials';
                        break;
                    default:
                        message = error;
                }
            }
        } catch (error) {
            message = 'Internal Server Error';
        } finally {
            yield put(thirdPartyAuthFailed(message));
        }
    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

export function* watchVerifyEmail() {
    yield takeEvery(VERIFY_EMAIL, verifyEmail);
}

export function* watchTriggerOtp() {
    yield takeEvery(TRIGGER_OTP, triggerOtp);
}

export function* watchThirdPartyAuth() {
    yield takeEvery(THIRD_PARTY_AUTH, thirdPartyAuth);
}

function* authSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgetPassword),
        fork(watchVerifyEmail),
        fork(watchTriggerOtp),
        fork(watchThirdPartyAuth)
    ]);
}

export default authSaga;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Alert } from '@material-ui/lab';

import { verifyEmail } from '../../redux/actions';
import { isUserAuthenticated } from '../../lib/authUtils';
// import Loader from '../../components/loader';
import Layout from './layout';
import Spinner from '../../components/spinner';

import '../../assets/styles/css/pages/auth/index.css';
import InputField from '../../components/inputField';

class Confirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                verificationCode: null
            }
        };
    }

    /**
     * Redirect to root
     */
    renderRedirectToRoot = () => {
        const isAuthTokenValid = isUserAuthenticated();
        if (isAuthTokenValid || (this.props.user && this.props.user.isVerified)) {
            return <Redirect to="/" />;
        }
    };

    handleValidSubmit = (event) => {
        event.preventDefault();
        const { email, sellerID, buyerID } = this.props.user;
        const { verificationCode } = this.state.form;

        const payloadData = {
            email,
            otp: verificationCode
        };
        if (sellerID) {
            payloadData.sellerID = sellerID;
        } else {
            payloadData.buyerID = buyerID;
        }

        this.props.verifyEmail(payloadData);
    };

    renderRedirectToLogin = () => {
        if (this.props.user && !this.props.user.isVerified) {
            return <Redirect to={`/auth/login/buyer`} />;
        }
    };

    handleOnChange = (event, formStateField) => {
        const state = this.state;
        state.form[formStateField] = event.target.value;

        this.setState(state);
    };

    handleOnBlur = (formStateField) => {
        if (this.state.form[formStateField] === null) {
            const state = this.state;
            state.form[formStateField] = '';

            this.setState(state);
        }
    };

    showError = (formStateField) => {
        return this.state.form[formStateField] === '';
    };

    isValidForm = () => {
        const formStateKeys = Object.keys(this.state.form);
        let invalidCount = 0;
        formStateKeys.forEach((formStateKeys) => {
            if (this.state.form[formStateKeys] === true || !this.state.form[formStateKeys]) {
                invalidCount++;
            }
        });

        return invalidCount > 0 ? false : true;
    };

    render() {
        // const isAuthTokenValid = isUserAuthenticated();
        const { form } = this.state;

        return (
            <>
                {this.props.loading ? <Spinner /> : null}
                {/* {this.renderRedirectToLogin()} */}
                {this.renderRedirectToRoot()}

                {/* {!isAuthTokenValid && this.props.user ? ( */}
                <>
                    <Layout>
                        <div className="header-text">Authentication</div>

                        <div className="mb-3">
                            <div>
                                A Verification mail is sent to <strong></strong>
                            </div>
                            <div>Enter the verification code to verify the account.</div>
                        </div>

                        <form
                            className="login-form"
                            autoComplete="off"
                            onSubmit={(event) => this.handleValidSubmit(event)}
                        >
                            <div className="input-field-container">
                                <InputField
                                    inputType="number"
                                    type="authForm"
                                    label="Verification Code"
                                    className="w-100 form-field"
                                    value={form.email}
                                    onChange={(event) =>
                                        this.handleOnChange(event, 'verificationCode')
                                    }
                                />
                            </div>

                            <div className="already-have-account-text">
                                <Link>Resend Code</Link>
                            </div>

                            {this.props.error ? (
                                <Alert severity="error">{this.props.error}</Alert>
                            ) : null}

                            <div className="signup-button-container">
                                <button
                                    type="submit"
                                    className={`signup-button ${
                                        !this.isValidForm() ? 'disabled' : ''
                                    }`}
                                    disabled={this.isValidForm() ? false : true}
                                >
                                    Confirm
                                </button>
                            </div>
                        </form>
                    </Layout>
                </>
                {/* ) : null} */}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { user, loading, error } = state.Auth;
    return { user, loading, error };
};

export default connect(mapStateToProps, { verifyEmail })(withRouter(Confirm));

import React, { Component } from 'react';
import Slider from 'react-slick';
import LazyImage from '../../components/lazyImage';
import buySideRep from '../../assets/images/our-process/slide-1.jpg';
import buySideRep1 from '../../assets/images/our-process/slide-2.jpg';
import buySideRep2 from '../../assets/images/our-process/slide-3.jpg';
import buySideRepPoster from '../../assets/images/our-process/slide-1.jpg';

const slideImage = [
    {
        image: buySideRep,
        placeholderImg: buySideRepPoster,
        alt: 'm & a experts',
        title: 'm & a experts',
        className: 'service-image home-service-img'
    },
    {
        image: buySideRep1,
        placeholderImg: buySideRepPoster,
        alt: 'm & a experts',
        title: 'm & a experts',
        className: 'service-image home-service-img'
    },
    {
        image: buySideRep2,
        placeholderImg: buySideRepPoster,
        alt: 'm & a experts',
        title: 'm & a experts',
        className: 'service-image home-service-img'
    }
];

export default class SimpleSlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 4000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            arrows: false
        };
        return (
            <div>
                <Slider {...settings}>
                    {slideImage.map((slideImage, index) => (
                        <div>
                            <LazyImage
                                className={slideImage.className}
                                src={slideImage.image}
                                placeholderImg={slideImage.placeholderImg}
                                alt={slideImage.alt}
                                title={slideImage.title}
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        );
    }
}

import spinnerLogo from '../assets/images/logo-spinner.png';

import '../assets/styles/css/components/spinner.css';

const Spinner = () => {
    return (
        <>
            <div className="spinner-container">
                <img src={spinnerLogo} className="spinner" alt="logo-spinner" />
            </div>
        </>
    );
};

export default Spinner;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-group-item {
  width: 100%;
  background-color: transparent !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.company-list {
  margin-top: 80px;
  background-color: #f6f6f6;
}
.company-list li + li {
  margin-top: 15px;
}
.company-list li .media img {
  width: 55px;
  height: 55px;
}
.company-list li .media .media-body h5 {
  font-weight: 500;
  font-size: 16px;
}
.company-list li .btn-pill {
  border-radius: 6.1875rem;
  background-color: #101b1f;
  min-width: 95px;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/css/pages/company/companyList.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,wCAAA;EAEA,wBAAA;EACA,sBAAA;EACA,uBAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,yBAAA;AAAJ;AAGQ;EACI,gBAAA;AADZ;AAIY;EACI,WAAA;EACA,YAAA;AAFhB;AAKgB;EACI,gBAAA;EACA,eAAA;AAHpB;AAOQ;EACI,wBAAA;EACA,yBAAA;EACA,eAAA;EACA,WAAA;AALZ","sourcesContent":[".list-group-item {\n    width: 100%;\n    background-color: transparent !important;\n    // height: 100px;\n    display: flex !important;\n    flex-direction: column;\n    justify-content: center;\n}\n\n.company-list {\n    margin-top: 80px;\n    background-color: #f6f6f6;\n\n    li {\n        + li {\n            margin-top: 15px;\n        }\n        .media {\n            img {\n                width: 55px;\n                height: 55px;\n            }\n            .media-body {\n                h5 {\n                    font-weight: 500;\n                    font-size: 16px;\n                }\n            }\n        }\n        .btn-pill {\n            border-radius: 6.1875rem;\n            background-color: #101b1f;\n            min-width: 95px;\n            color: #fff;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

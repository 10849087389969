import React, { Component } from 'react';
import Slider from 'react-slick';
import LazyImage from './lazyImage';
import TopSlide from '../assets/images/Topslide.jpg';
import TopSlide1 from '../assets/images/about/slide-1.jpg';
import TopSlide2 from '../assets/images/about/slide-2.jpg';

const slideImage = [
    {
        image: TopSlide,
        alt: 'new company mergers',
        title: 'new company mergers',
        className: 'service-image TopSlide img-fluid'
    },
    {
        image: TopSlide1,
        alt: 'new company mergers',
        title: 'new company mergers',
        className: 'service-image TopSlide img-fluid'
    },
    {
        image: TopSlide2,
        alt: 'new company mergers',
        title: 'new company mergers',
        className: 'service-image TopSlide img-fluid'
    }
];

export default class AboutusSlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            arrows: false
        };
        return (
            <div>
                <Slider {...settings}>
                    {slideImage.map((slideImage, index) => (
                        <div>
                            <LazyImage
                                className={slideImage.className}
                                src={slideImage.image}
                                alt={slideImage.alt}
                                title={slideImage.title}
                            />
                        </div>
                    ))}

                </Slider>
            </div>
        );
    }
}

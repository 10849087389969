import React from 'react';
import { GoogleLogin } from 'react-google-login';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const GoogleBtn = ({ onSuccessGoogleAuth, onFailureGoogleAuth }) => {
    return (
        <GoogleLogin
            clientId={CLIENT_ID}
            onSuccess={onSuccessGoogleAuth}
            onFailure={onFailureGoogleAuth}
            cookiePolicy={'single_host_origin'}
            render={({ onClick, disabled }) => (
                <div
                    id="google-dark"
                    className="media-icon fab fa-google google-style"
                    onClick={onClick}
                    disabled={disabled}
                />
            )}
        />
    );
};

export default GoogleBtn;

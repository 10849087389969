const utils = {};

utils.formatDate = (date) => {
    if (date) {
        var newDate;
        if (typeof date === 'string') {
            newDate = new Date(date);
        } else {
            newDate = date;
        }
        return `${newDate.getDate()}-${newDate.getMonth() + 1}-${newDate.getFullYear()}`;
    }
};

utils.formatDateInter = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

utils.formatTime = (dateString) => {
    const date = new Date(dateString);
    return `${date.getHours()}:${date.getMinutes()}`;
};

utils.getMonthStart = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
};

utils.isEmpty = (val) => {
    return val === null ||
        val === '' ||
        val === 0 ||
        JSON.stringify(val) === '{}' ||
        JSON.stringify(val) === '[]'
        ? true
        : false;
};

utils.subtractDate = (dateString, to) => {
    var toDate;
    if (to) {
        toDate = new Date(to);
    } else {
        toDate = new Date();
    }
    const inputDate = new Date(dateString);
    return Math.ceil((toDate - inputDate) / (1000 * 60 * 60 * 24));
};

utils.formOptions = (valueArr) => {
    const formedOptions = [];
    valueArr.map((value) => {
        return formedOptions.push(
            <option key={`${value}-options`} value={value}>
                {value}
            </option>
        );
    });
    return formedOptions;
};

utils.getPreviousDates = (previousDate) => {
    const previousDates = [];

    for (var i = 0; i < previousDate; i++) {
        previousDates.push(
            utils.formatDate(new Date(new Date().setDate(new Date().getDate() - i)))
        );
    }
    return previousDates;
};

utils.removeArrayDuplicates = (array) => {
    return [...new Set(array)];
};

utils.modifyDate = (dateObj, daysToModify) => {
    return dateObj.setDate(dateObj.getDate() + daysToModify);
};

utils.getFieldFromArray = (array, field, value) => {
    if (array && array.length) {
        const resultArr = array.filter((obj) => obj[field] === value);
        return resultArr;
    }
};

utils.getSign = (number) => {
    switch (Math.sign(number)) {
        case 1:
            return '-';
        case -1:
            return '+';
        default:
            return '0';
    }
};

utils.isBuyer = (role) => {
    if (role === 'isBuyer' || role === 'buyer') {
        return true;
    } else if (role === 'isSeller' || role === 'seller') {
        return false;
    }
};

utils.getUserRole = (user) => {
    if (user.sellerID) {
        return 'Seller';
    } else if (user.buyerID) {
        return 'Buyer';
    }
};

utils.getRoleFromUrl = (props) => {
    if (props && props.match && props.match.params && props.match.params.role) {
        return props.match.params.role;
    } else {
        return null;
    }
};

utils.letterAvatar = (name, size) => {
    let w = window;
    let d = document;
    name = name || '';
    size = size || 60;

    var colours = [
            '#1abc9c',
            '#2ecc71',
            '#3498db',
            '#9b59b6',
            '#34495e',
            '#16a085',
            '#27ae60',
            '#2980b9',
            '#8e44ad',
            '#2c3e50',
            '#f1c40f',
            '#e67e22',
            '#e74c3c',
            '#ecf0f1',
            '#95a5a6',
            '#f39c12',
            '#d35400',
            '#c0392b',
            '#bdc3c7',
            '#7f8c8d'
        ],
        nameSplit = String(name).toUpperCase().split(' '),
        initials,
        charIndex,
        colourIndex,
        canvas,
        context,
        dataURI;

    if (nameSplit.length === 1) {
        initials = nameSplit[0] ? nameSplit[0].charAt(0) : '?';
    } else {
        initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
    }

    if (w.devicePixelRatio) {
        size = size * w.devicePixelRatio;
    }

    charIndex = (initials === '?' ? 72 : initials.charCodeAt(0)) - 64;
    colourIndex = charIndex % 20;
    canvas = d.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    context = canvas.getContext('2d');
    context.fillStyle = utils.hexToRGB(colours[colourIndex - 1]);
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = Math.round(canvas.width / 2) + 'px Arial';
    context.textAlign = 'center';
    context.fillStyle = '#fff';
    context.fillText(initials, size / 2, size / 1.5);

    dataURI = canvas.toDataURL();
    canvas = null;

    return dataURI;
};

utils.hexToRGB = (hex, alpha) => {
    hex = hex || '#000';
    alpha = alpha || '0.5';
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
};

utils.parseDate = (dateString) => {
    if (dateString) {
        const dateArr = dateString.split('/');
        return {
            date: +dateArr[0],
            month: +dateArr[1],
            year: +dateArr[2]
        };
    }
};

utils.shouldHideHeader = (props) => {
    if (props && props.location && props.location.pathname) {
        const pathName = props.location.pathname;
        const pathToHideHeader = ['auth'];
        const pathArr = pathName.split('/');

        return pathToHideHeader.includes(pathArr[1]) ? true : false;
    }
};

utils.isHomePage = (props) => {
    if (props && props.location && props.location.pathname) {
        const pathName = props.location.pathname;
        return pathName === '/home' ? true : false;
    }
};

export default utils;

import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import SimpleSlider from '../../components/mainBannerSlide.js';
import '../../assets/styles/css//pages/home/videoContainer.css';

const VideoContainer = () => {
    return (
        <div className="container-fluid p-0">
            <div className="layout-row">
                <div md={12} className="p-0">
                    <section className="component-banner">
                        <SimpleSlider />
                    </section>
                </div>
            </div>
        </div>
    );
};

export default VideoContainer;

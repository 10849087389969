import React from 'react';
import { Slider } from '@material-ui/core';

import CheckboxWithLabel from '../../components/checkbox';

import '../../assets/styles/css/pages/company/companyFilter.scss';

export default class CompanyFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mainFilters: []
        };
    }

    handleFilterChange = (event, checkedValue) => {
        const { mainFilters } = this.state;
        const { data } = this.props;

        const subFilterCheckList = data.length
            ? [...new Set(data.map((updatedData) => updatedData[checkedValue]))]
            : [];
        const updatedFilterObj = { value: checkedValue, subFilterCheckList: subFilterCheckList };

        if (event.target.checked) {
            mainFilters.push(updatedFilterObj);
        } else {
            mainFilters.splice(
                mainFilters.findIndex((filterObj) => filterObj.value === checkedValue),
                1
            );
            this.props.resetData(checkedValue);
        }

        this.setState({ mainFilters });
    };

    handleFilterSearch = (event, checkedValue) => {
        const { mainFilters } = this.state;
        const { data } = this.props;
        const search = event.target.value.toLowerCase();

        const mainFilter = mainFilters.filter((mainFilter) => mainFilter.value === checkedValue);

        const subFilterCheckList = search
            ? mainFilter[0].subFilterCheckList.filter((val) =>
                  JSON.stringify(val).toLowerCase().includes(search)
              )
            : data.length
            ? [...new Set(data.map((updatedData) => updatedData[checkedValue]))]
            : [];

        mainFilters.map((obj) =>
            obj.value === checkedValue ? (obj.subFilterCheckList = subFilterCheckList) : null
        );
        this.setState({ mainFilters });
    };

    getCheckedFilter = (value) => {
        const result = this.state.mainFilters.filter((checkedItem) => {
            return checkedItem.value === value;
        });

        return result;
    };

    render() {
        const { checkBoxList, sortList } = this.props;

        return (
            <div className="filter">
                <div className="col">
                    <div className="form-group-sort">
                        <label className="sort">Sort By</label>
                        <br />
                        <select
                            className="sortby_dropdown"
                            label={'search by '}
                            onChange={this.props.handleSort}
                        >
                            {sortList.map(({ value, label }, index) => (
                                <option value={value} key={`main-dropdown-options-${index}`}>
                                    {label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <label>Filter By</label>
                        <div className="filter_list">
                            {checkBoxList.map(({ value, label, type, min, max }, index) => (
                                <div key={`sub-dropdown-options-${index}`}>
                                    <CheckboxWithLabel
                                        label={label}
                                        defaultChecked={false}
                                        onChange={(event) => this.handleFilterChange(event, value)}
                                    />
                                    {this.getCheckedFilter(value).length && type === 'text' ? (
                                        <div>
                                            <div className="input-group input-group-merge mb-3 mt-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={`search by ${label}`}
                                                    onChange={(event) =>
                                                        this.handleFilterSearch(event, value)
                                                    }
                                                />
                                                <div className="input-group-append cursor-pointer">
                                                    <span
                                                        className="input-group-text"
                                                        id="basic-addon1"
                                                    >
                                                        <div id="search-icon" />
                                                    </span>
                                                </div>
                                            </div>
                                            <ul className="list-group list-group-flush mb-3 sub-filter-list ">
                                                {this.getCheckedFilter(
                                                    value
                                                )[0].subFilterCheckList.map((subFilterValue) => (
                                                    <li className="list-group-item border-0 pt-1 pb-1">
                                                        <CheckboxWithLabel
                                                            label={subFilterValue}
                                                            defaultChecked={false}
                                                            onChange={(event) =>
                                                                this.props.handleFilterChange(
                                                                    event,
                                                                    value,
                                                                    subFilterValue
                                                                )
                                                            }
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ) : this.getCheckedFilter(value).length && type === 'number' ? (
                                        <Slider
                                            onChange={(event, newValue) =>
                                                this.props.handleSliderChange(newValue, value)
                                            }
                                            defaultValue={min}
                                            min={min}
                                            max={max}
                                            valueLabelDisplay="auto"
                                            aria-labelledby="continuous-slider"
                                        />
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

import { TRIGGER_MAIL, TRIGGER_MAIL_FAILED, TRIGGER_MAIL_SUCCESS } from './constants';

export const brodcastMail = (recipient, subject, body) => ({
    type: TRIGGER_MAIL,
    payload: { recipient, subject, body }
});

export const brodcastMailSuccess = (mailStatus) => ({
    type: TRIGGER_MAIL_SUCCESS,
    payload: mailStatus
});

export const brodcastMailFailed = (error) => ({
    type: TRIGGER_MAIL_FAILED,
    payload: error
});

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import '../../assets/styles/css/pages/company/landingPage.css';
import landing from '../../assets/images/search_page.jpeg';

export class LandingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                search: ''
            }
        };
    }

    handleOnChange = (event, formStateField) => {
        const state = this.state;
        state.form[formStateField] = event.target.value;

        this.setState(state);
    };

    render() {
        return (
            <div className="search-container">
                <div className="background_area">
                    <h1 className="text_acquiredream">Acquire your Dream Company</h1>
                    <p className="text_findbestbusiness">
                        Find the best business opportunity across the world!
                    </p>
                </div>

                <div>
                    <img className="background" src={landing} alt="background" />
                    <div className="overlay-img" />
                </div>
                <div className="search">
                    <div className="search-bar-width">
                        <label>Enter your Personal Referral Code</label>
                        <div className="row ">
                            <div className="col-md-12">
                                <div className="input-group mb-3 input-group-merge">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search by Company, Cities"
                                        onChange={(event) => this.handleOnChange(event, 'search')}
                                    />
                                    <div className="input-group-append cursor-pointer">
                                        <span className="input-group-text" id="basic-addon1">
                                            <div id="search-icon" />
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <p className="label-left">
                                        Don't have a referral code? Browse{' '}
                                        <Link to="/search/company">Here</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LandingPage;

import React from 'react';

import LazyImage from '../../components/lazyImage';

import buyside from '../../assets/images/buy-side-service.jpg';
import buySidePoster from '../../assets/images/buy-side-service.jpg';

import '../../assets/styles/css/pages/services/services.css';
import uiData from './buySideData.json';

const BuySideRep = () => {
    return (
        <div id="buySide" className="">
            <div className="row mt-4">
                <div className="col-md-12 col-lg-8">
                    <div className="card mb-0">
                        <div className="card-body pt-0">
                            <h2 className="buy_side_representation_header">Buy-Side Representation</h2>
                            <p className="buy_side_representation_content">
                                <span className="first_letter_text">T</span>he team of experts at Axis M&A
                                focuses on M&A in the the Technology and Staffing Sectors. Because of the
                                ever-growing portfolio at Axis M&A, we have great relationships with those
                                interested in merging with or acquiring a tech or staffing company. The
                                experts at <a className="text-dark" href="/">Axis M&A</a> are so familiar with these industries that we are
                                positioned to help interested parties acquire a firm in an industry-leading
                                time frame.
                            </p>
                            <p className="buy_side_representation_content">
                                Axis M&A’s expertise is particularly valuable with buy-side transactions
                                because our Founder is a seasoned staffing entrepreneur. Between multiple
                                staffing companies and years of M&A experience, the leadership at Axis M&A
                                is positioned to understand not only the economics of the deal, but the
                                actual intricacies and operations of the target business. This is a valuable
                                asset that very few Axis M&A competitors can claim, and makes Axis a true
                                industry leader.
                            </p>
                            
                            <p className="buy_side_representation_content">
                               <b> Here is a list of additional ways we can help you:</b>
                            </p>

                            <ul className="buy-side-ul">
                                {uiData.map(items => {
                                    return (
                                        <li>{items.content}</li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>

                </div>
                <div className="col-md-12 col-lg-4">
                    <div className="card">
                        <div className="card-body">
                            <LazyImage
                                className="buy_side_representation_image img-fluid"
                                src={buyside}
                                alt="m & a consultants"
                                title="m & a consultants"
                                placeholderImg={buySidePoster}
                            />

                            <p className="buy_side_representation_content">
                                From identifying and lining up support services to assist with specific aspects of a transaction, to managing the entire process, our experts at Axis M&A tailor their approach to each client’s specific needs. As a buy side advisor, we can identify marketplace trends, acquisition opportunities and assist in bringing potential transactions to close.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BuySideRep;

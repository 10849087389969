import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { allFlattenRoutes as routes } from './index';
import Header from '../components/Header';

const Routes = () => (
    // rendering the router with layout
    <BrowserRouter>
        <Switch>
            <>
                <Header />
                {routes.map((route, index) => {
                    return !route.children ? (
                        route.route ? (
                            <route.route
                                key={index}
                                path={route.path}
                                roles={route.roles}
                                exact={route.exact}
                                component={route.component}
                            />
                        ) : (
                            <Route
                                key={index}
                                path={route.path}
                                roles={route.roles}
                                exact={route.exact}
                                component={(props) => <route.component {...props} />}
                            />
                        )
                    ) : null;
                })}
            </>
        </Switch>
    </BrowserRouter>
);

export default Routes;

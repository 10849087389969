import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import imgServerDown from '../../assets/images/server-down.png';

class Error500 extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="account-pages my-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <col className="col col-xl-4 col-lg-5">
                                <div className="text-center">
                                    <div>
                                        <img src={imgServerDown} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </col>
                        </div>

                        <div className="row">
                            <div className="col text-center">
                                <h3 className="mt-3">Opps, something went wrong</h3>
                                <p className="text-muted mb-5">
                                    Server Error 500. We apoligise and are fixing the problem.
                                    <br /> Please try again at a later stage.
                                </p>

                                <Link to="/" className="btn btn-lg btn-primary mt-4">
                                    Take me back to Home
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Error500;

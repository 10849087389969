import utils from './utils';

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const RestCall = async (url, options) => {
    try {
        if (options && options.body && options.body.role) {
            if (utils.isBuyer(options.body.role)) {
                options.body.isBuyer = true;
            } else {
                options.body.isSeller = true;
            }
            delete options.body.role;
        }
        options.body = JSON.stringify(options.body);

        // const finalUrl = `http://127.0.0.1:8080/api${url}`;
        const finalUrl = process.env.REACT_APP_BASE_URL + url;

        try {
            const response = await fetch(finalUrl, options);

            if (response && response.status === 200) {
                const json = response.json();
                if (json.errorCode) {
                    throw json;
                } else {
                    return json;
                }
            } else {
                throw response;
            }
        } catch (error) {
            throw error;
        }
    } catch (error) {
        throw error;
    }
};

export { RestCall };

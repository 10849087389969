/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILED = 'VERIFY_EMAIL_FAILED';
export const TRIGGER_OTP = 'TRIGGER_OTP';
export const TRIGGER_OTP_SUCCESS = 'TRIGGER_OTP_SUCCESS';
export const TRIGGER_OTP_FAILED = 'TRIGGER_OTP_FAILED';
export const THIRD_PARTY_AUTH = 'THIRD_PARTY_AUTH';
export const THIRD_PARTY_AUTH_SUCCESS = 'THIRD_PARTY_AUTH_SUCCESS';
export const THIRD_PARTY_AUTH_FAILED = 'THIRD_PARTY_AUTH_FAILED';

import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { X, Menu as MenuIcon } from 'react-feather';
import {
    Avatar,
    Menu,
    MenuItem,
    Badge,
    Divider,
    MuiThemeProvider,
    createMuiTheme,
    Tabs,
    Tab
} from '@material-ui/core';

import 'bootstrap/dist/css/bootstrap.css';
import { logoutUser } from '../redux/actions';
import SideBar from '../pages/common/sideBar';
import logoLight from '../assets/images/logo.png';
import logoDark from '../assets/images/logo.png';
import utils from '../lib/utils';
import { isUserAuthenticated } from '../lib/authUtils';
// import { isUserAuthenticated, getLoggedInUser } from '../lib/authUtils';
// import HanburgerIcon from './hanburgerIcon';

import '../assets/styles/css/components/header.css';
// import '../assets/styles/css/common/sprite.css';

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMenuOpen: false,
            scrollOnTop: false,
            logo: logoLight,
            isProfileOpen: false,
            anchorEl: null,
            logoShow: true
        };

        this.getHeaderItemColor = this.getHeaderItemColor.bind(this);
        this.themeOptions = this.themeOptions.bind(this);
    }

    themeOptions = () => {
        return {
            // overrides: {
            //     MuiMenuItem: {
            //         root: {
            //             fontWeight: '600',
            //             fontSize: '14px',
            //             // padding: '12px 16px !important'
            //         }
            //     },
            //     MuiMenu: {
            //         paper: {
            //             width: '200px'
            //         }
            //     },
            //     MuiTab: {
            //         root: {
            //             color: this.getHeaderItemColor(),
            //             textTransform: 'none',
            //             minWidth: '120px !important',
            //             fontSize: '20px'
            //         },
            //         wrapper: {
            //             '&:hover': {
            //                 color: this.getHeaderItemColor()
            //             },
            //             '&:focus': {
            //                 color: this.getHeaderItemColor()
            //             }
            //         }
            //     },
            //     PrivateTabIndicator: {
            //         colorPrimary: {
            //             backgroundColor: this.getHeaderItemColor()
            //         }
            //     }
            // },
            // palette: {
            //     primary: {
            //         main: '#fff'
            //     },
            //     secondary: {
            //         main: '#000'
            //     },
            //     error: {
            //         main: '#f44242'
            //     }
            // },
            // typography: {
            //     useNextVariants: true,
            //     fontFamily: 'Gill Sans'
            // }
        };
    };

    componentDidMount() {
        this.handleScroll();
        this.handleHeaderStyles();
        window.scrollTo(0, 0);
    }

    handleHeaderStyles() {
        window.addEventListener('scroll', () => {
            this.handleScroll();
        });
    }

    handleScroll = () => {
        const isMenuOpen = this.state.isMenuOpen;
        const scroll_top = window.scrollY;

        if (!isMenuOpen) {
            if (scroll_top === 0) {
                this.setState({
                    logo:
                        utils.isHomePage(this.props) || this.getHeaderItemColor(true) === 'white'
                            ? logoLight
                            : logoDark,
                    scrollOnTop: true,
                    logoShow: true
                });
            } else {
                this.setState({
                    logo: logoDark,
                    scrollOnTop: true,
                    logoShow: false
                });
            }
        } else {
            this.setState({
                logo: logoLight
            });
        }
    };

    openMenu = () => {
        this.setState({ isMenuOpen: true, logo: logoLight });
    };

    closeMenu = (event, isLogout) => {
        if (isLogout) {
            this.onClickLogout();
        }
        this.setState({
            isMenuOpen: false,
            logo: this.getHeaderItemColor() ? logoLight : logoDark
        });
        window.scrollTo(0, 0);
    };

    handleMenuToggle = (event) => {
        const { isProfileOpen } = this.state;
        if (isProfileOpen) {
            this.setState({ isProfileOpen: !isProfileOpen });
        } else {
            window.scrollTo(0, 0);
            this.setState({ isProfileOpen: !isProfileOpen });
        }
    };

    onClickLogout = () => {
        this.props.logoutUser(this.props.history);
    };

    getHeaderItemColor = (withoutTopScroll) => {
        const { location } = this.props;
        const { scrollOnTop } = this.state;

        if (location && location.pathname) {
            const pathName = location.pathname;
            const whiteColorHeader = ['/'];

            if (withoutTopScroll) {
                return whiteColorHeader.includes(pathName) ? 'white' : 'white';
            } else {
                return whiteColorHeader.includes(pathName) && scrollOnTop ? 'white' : 'white';
            }
        }
    };

    // componentDidUpdate(prevProps) {
    //     if (this.props.location) {
    //         if (prevProps.location.pathname !== this.props.location.pathname) {
    //             this.handleScroll();
    //         }
    //     }
    // }

    render() {
        const { isMenuOpen, logo, anchorEl, isProfileOpen } = this.state;

        // const getScrollClassNames = () => {
        //     if (isMenuOpen) {
        //         return 'bg-rgba(51,51,51,255)';
        //     } else {
        //         return scrollOnTop ? 'bg-rgba(51,51,51,255)' : 'bg-rgba(51,51,51,255)';
        //     }
        // };

        if (utils.shouldHideHeader(this.props)) {
            return null;
        }

        return (
            <>
                <header className="header">
                    <div className="container-fluid">
                        <nav className={` navbar row no-gutters`}>
                            {/* <div className="container"> */}
                           
                            <div className="col">
                                <Link to="/" className="logo">
                                    <img
                                        src={logo}
                                        id="logo"
                                        alt="logo-light"
                                        // className={`mine ${logoShow ? '' : 'logo-inactive'}`}
                                    />
                                </Link>
                            </div>
                            <div className="col search-color">
                                <div className="searchbar-top">
                                    <input
                                        type="text"
                                        placeholder="search will be enabled after login"
                                        className="search-img"  
                                        
                                    >
                                    </input>
                                </div>
                                {/* <HanburgerIcon
                            toggleMenu={this.toggleMenu}
                            color={`${scrollUp ? 'bg-black' : 'bg-white'}`}
                        /> */}
                                {/* </div> */}
                            <div className="hum-icon-div">
                            {!utils.isHomePage(this.props) && isUserAuthenticated() ? (
                                <MuiThemeProvider theme={createMuiTheme(this.themeOptions())}>
                                    <Tabs
                                        value={1}
                                        indicatorColor="primary"
                                        textColor="white"
                                        className="nav-items-container"
                                    >
                                        <Tab
                                            label="Home"
                                            onClick={() => this.props.history.push('/')}
                                        />
                                        <Tab label="Search" />
                                    </Tabs>

                                    <Avatar
                                        className="cursor-pointer"
                                        onClick={this.handleMenuToggle}
                                    >
                                        N
                                    </Avatar>

                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        open={isProfileOpen}
                                        onClose={this.handleMenuToggle}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                        disableScrollLock
                                    >
                                        <MenuItem onClick={this.handleMenuToggle}>
                                            <div className="d-flex align-items-center">
                                                <div id="profile-dark" className="mr-2" />
                                                <span>My Profile</span>
                                            </div>
                                        </MenuItem>
                                        <MenuItem onClick={this.handleMenuToggle}>
                                            <div className="d-flex align-items-center">
                                                <div id="account-dark" className="mr-2" />
                                                <span>Account</span>
                                            </div>
                                        </MenuItem>
                                        <MenuItem onClick={this.handleMenuToggle}>
                                            <div className="d-flex align-items-center">
                                                <div id="notification-dark" className="mr-2" />
                                                <span>Notifications</span>
                                                <Badge
                                                    badgeContent={4}
                                                    color="error"
                                                    className="ml-4"
                                                />
                                            </div>
                                        </MenuItem>
                                        <MenuItem onClick={this.handleMenuToggle}>
                                            <div className="d-flex align-items-center">
                                                <div id="setting-dark" className="mr-2" />
                                                <span>Settings</span>
                                            </div>
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem onClick={this.onClickLogout}>
                                            <div className="d-flex align-items-center">
                                                <div id="logout-dark" className="mr-2" />
                                                <span>Logout</span>
                                            </div>
                                        </MenuItem>
                                    </Menu>
                                </MuiThemeProvider>
                            ) : isMenuOpen ? (
                                <X
                                    color="white"
                                    className="cursor-pointer"
                                    onClick={this.closeMenu}
                                />
                            ) : (
                                <MenuIcon
                                    color={this.getHeaderItemColor()}
                                    className="cursor-pointer menu-responsive"
                                    onClick={this.openMenu}
                                />
                            )}
                            </div>
                            </div>
                        </nav>
                    </div>
                </header>
                <SideBar isMenuOpen={isMenuOpen} onClickMenuItems={this.closeMenu} />
            </>
        );
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleHeaderStyles);
    }
}

const mapStateToProps = (state) => {
    const { user, loading, error } = state.Auth;
    return { user, loading, error };
};

export default connect(mapStateToProps, { logoutUser })(withRouter(Header));

import React from 'react';
import { Link } from 'react-router-dom';

import '../assets/styles/css/components/search.scss';

const Search = (props) => {
    const handleSearchFilter = (event) => {
        const { data, onChange } = props;
        const target = event.target;
        const value = target.value.toLowerCase();
        const dataPost = value
            ? data.filter(
                  ({ companyName, city, country }) =>
                      companyName.toLowerCase().includes(value) ||
                      city.toLowerCase().includes(value) ||
                      country.toLowerCase().includes(value)
              )
            : data;

        onChange(dataPost);
    };

    return (
        <div className=" top-search-content">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 search-contianer">
                        <div className="form-group">
                            <label className="input-label float-left">
                                Search Your Dream Company
                            </label>
                            <div className="input-group mb-3 input-group-merge">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by Company, Cities"
                                    onChange={handleSearchFilter}
                                />
                                <div className="input-group-append cursor-pointer">
                                    <span className="input-group-text" id="basic-addon1">
                                        <div id="search-icon" />
                                    </span>
                                </div>
                            </div>
                            <p className="input-label float-right color-black">
                                Have a referral code? Browse{' '}
                                <Link to="/search" className="cursor-pointer">
                                    Here
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Search;
